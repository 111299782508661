import * as React from 'react';
import { List, SimpleList, Datagrid, TextField, FunctionField } from 'react-admin';
import { useMediaQuery } from '@mui/material';

const roundedImageStyle = {
  borderRadius: "50%",
  width: "50px",
  height: "50px",
  objectFit: "cover", 
};

export const SupportList = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  
  return (
    <List {...props} title="Tukipyynnöt">
      {isSmall ? (
        <SimpleList
          primaryText={record => record.agentId ? record.agentId.fullName : ''}
          secondaryText={record => record.userMessage ? record.userMessage : ''}
          leftAvatar={record => 
            <img 
              style={roundedImageStyle}
              src={(record.agentId && record.agentId.profilePicture) ? record.agentId.profilePicture : 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'}  
              alt="kuva" 
            />
          }
        />
      ) : (
        <Datagrid rowClick="edit">
          <FunctionField label="Kuva" render={record => 
            <img 
              style={roundedImageStyle}
              src={(record.agentId && record.agentId.profilePicture) ? record.agentId.profilePicture : 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'}  
              alt="kuva" 
            />} 
          />
          <FunctionField label="Agentin nimi" render={record => record.agentId ? record.agentId.fullName : ''} />
          <FunctionField label="Yritys" render={record => record.agentId ? record.agentId.companyName : ''} />
          <FunctionField label="Kohteen osoite" render={record => record.enquiryId ? record.enquiryId.address : ''} />
          <FunctionField label="Asiakkaan nimi" render={record => record.enquiryId ? record.enquiryId.name : ''} />
          <TextField source="userMessage" label="Käyttäjän viesti" />
        </Datagrid>
      )}
    </List>
  );
};

