import React, { useEffect, useState } from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import myDataProvider from './myDataProvider'; // Oletetaan, että getListWithoutPagination on määritelty tässä

export const AgencysList = props => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await myDataProvider.getListWithoutPagination('listAgencies', {
          filter: {}, // Voit lisätä filttereitä, jos tarpeen
        });

        if (result && result.data && Array.isArray(result.data)) {
          setData(result.data);
          setTotal(result.total);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <List {...props} total={total} loading={loading}>
      <Datagrid>
        <TextField source="name" />
        {/* Lisää muita kenttiä tässä */}
      </Datagrid>
    </List>
  );
};
