import * as React from "react";
import { List, Datagrid, SimpleList, FunctionField, DateField, TextField, TextInput, Filter, Pagination} from 'react-admin';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { useMediaQuery } from "@mui/material";

const UserFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Haku" source="searchTerm" alwaysOn />
  </Filter>
);

export const UserList = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return (
    <List perPage={25} filters={<UserFilter />} title="Asiakkaat" {...props} filter={{ type: 'CUSTOMER' }} pagination={<Pagination rowsPerPageOptions={[10, 25, 50]} />}>
      {isSmall ? (
        <SimpleList
          primaryText={record => record.fullName}
          secondaryText={record => `${record.countryCode || ''} ${record.contactNumber || ''}`}
          tertiaryText={record => {
            const date = new Date(record.updatedAt);
            return date.toLocaleString('fi-FI', {
              day: '2-digit',
              month: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
            });
          }}
        />
      ) : (
        <Datagrid>
          <FunctionField
            label="Vahvistettu"
            render={record => {
              return record.otpVerify ? 
                  <CheckCircleIcon style={{ color: green[500] }} /> : 
                  <CancelIcon style={{ color: red[500] }} />;
            }}
          />
          <TextField label="Nimi" source="fullName" />
          <FunctionField label="Puhelinnumero" render={record => `${record.countryCode || ''} ${record.contactNumber || ''}`} />
          <TextField label="Sähköposti" source="email" />
          <DateField label="Luotu" source="updatedAt" />
        </Datagrid>
      )}
    </List>
  );
};
