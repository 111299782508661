import * as React from "react";
import { List, Datagrid, FunctionField, DateField, TextField, TextInput, Filter, Pagination, BooleanField, SimpleList, Button, TopToolbar, FilterButton, ExportButton  } from 'react-admin';
import ToggleActiveButton from './ToggleActiveButton';
 import TogglebuttonIsActive from './TogglebuttonIsActive';
 import { useMediaQuery } from '@mui/material';


const AgentFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Haku" source="searchTerm" alwaysOn />
    </Filter>
);

const ListActions = ({ setFilter, setListKey, ...props  }) => {
  const handleToggleBlocked = () => {
    setFilter(prev => ({ isBlocked: prev.isBlocked !== true }));
    setListKey(prevKey => prevKey + 1); // Pakotetaan List-komponentti päivittymään
  };

  return (
    <TopToolbar>
      <Button onClick={handleToggleBlocked} label="|||" />
      {/* Voit lisätä muita painikkeita tai toimintoja tähän */}
    </TopToolbar>
  );
};

const roundedImageStyle = {
  borderRadius: "50%",
  width: "50px",
  height: "50px",
  objectFit: "cover", 
};

export const AgentList = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [filter, setFilter] = React.useState({ isBlocked: false });
  const [listKey, setListKey] = React.useState(0);
  


  
  return (  
    <>

    <List perPage={25} 
    filters={<AgentFilter />} 
    title="Kiinteistönvälittäjät" {...props} 
    filter={{ ...props.filter, ...filter, type: 'AGENT' }} 
    actions={<ListActions setFilter={setFilter} setListKey={setListKey} {...props} />}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50]} // Yhdistä alustavat suodattimet
    
    />}>
      {isSmall ? (
        <SimpleList
          primaryText={record => record.fullName}
          secondaryText={record => record.companyName}
          tertiaryText={record => record.city}
          linkType={record => record.canEdit ? "edit" : "show"}
          leftAvatar={record => record.profilePicture}
        />
      ) : (
        <Datagrid rowClick="edit">
          <FunctionField label="Profiilikuva" render={record => <img style={roundedImageStyle} src={record.profilePicture} alt="profiilikuva" width={50} />} />
          <TextField label="Nimi" source="fullName" />
          <FunctionField label="Puhelinnumero" render={record => `${record.countryCode || ''} ${record.contactNumber || ''}`} />
          <TextField label="Kaupunki" source="city" />
          <TextField label="Yritys" source="companyName" />
          <DateField label="Päivitetty" source="updatedAt" />
          <TextField label="Osoite" source="address" />
          <BooleanField label="Lomalla" source="vacationMode" />
          <FunctionField label="Tilin tila" render={record => <ToggleActiveButton record={record} resource="agents" />} />
          <FunctionField label="Aktiivinen" render={record => <TogglebuttonIsActive record={record} resource="agents" />} />
        </Datagrid>
      )}
   
    </List>
    </>
  );
};
