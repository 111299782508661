import * as React from 'react';
import { useState } from 'react';
import { useLogin, useNotify } from 'react-admin';
import { Button, TextField, Paper, Typography, makeStyles } from '@material-ui/core';

// Päivitetään tyylit
const useStyles = makeStyles({
  root: {
    backgroundImage: 'url("https://www.salaovi.fi/wp-content/uploads/2020/10/1900x600_salaovi-hero-image_5.jpg")',
    backgroundSize: 'cover', // Kuvan koko
    backgroundPosition: 'center', // Kuvan sijainti
    backgroundColor: '#399ecc', // Sininen tausta
    height: '100vh', // Täysi korkeus
    display: 'flex', // Käytetään flexboxia
    justifyContent: 'center', // Keskitys vaakasuunnassa
    alignItems: 'center' // Keskitys pystysuunnassa
  },
  paper: {
    maxWidth: '400px',
    textAlign: 'center',
    padding: '2em'
  },
  input: {
    marginBottom: '2em',
    width: '100%',
    height: '20px'
  },
  button: {
    width: '100%'
  },
  imageContainer: {
    position: 'relative',

  },
  image: {
    top: 0,
    left: 0,
    width: '300px',
  },
 
});


const MyLoginPage = ({ theme }) => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const login = useLogin();
    const notify = useNotify();

    const handleSubmit = e => {
        e.preventDefault();
        login({ email, password }).catch(() => notify('Väärä käyttäjä tai salasana'));
    };

    return (
        <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.imageContainer}>
        <img src="https://www.salaovi.fi/wp-content/uploads/2020/08/salaovi.png" alt="Kirjautumissivu Header" className={classes.image} />  {/* Lisätty kuva */}
        </div>
                <Typography variant="h5">Salaoven takaovi</Typography>
                <Typography variant="p">Kirjaudu sisään</Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        className={classes.input}
                        label="Sähköposti"
                        name="email"
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <TextField
                        className={classes.input}
                        label="Salasana"
                        name="password"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <Button className={classes.button} type="submit" color="primary" variant="contained">
                        Kirjaudu sisään
                    </Button>
                </form>
            </Paper>
        </div>
    );
};

export default MyLoginPage;

