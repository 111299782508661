import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { green, red } from '@material-ui/core/colors';




const AgentDetailButton = ({ record }) => {

  const [agentData, setAgentData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);

  const getAgentDetail = async () => {
    try {
      const token = localStorage.getItem('token');
      const options = {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Bearer ${token}`
        }),
        body: `id=${record._id}`,
      };
      const response = await fetch(`https://api.salaovi.fi/Admin/listSelectedAgent`, options);
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const json = await response.json();

      setAgentData(json.data);
      setShowModal(true);
    } catch (err) {
      setError(`Failed to fetch data: ${err}`);
    }
  };
  const sortedAgentData = agentData ? [...agentData].sort((a, b) => b.isSelected - a.isSelected) : [];

  return (
    <>
      <Button variant="contained" color="primary" onClick={getAgentDetail}>
        Välittäjät
      </Button>

      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogTitle>Välittäjät</DialogTitle>
        <DialogContent>
          <List>
            {sortedAgentData && Array.isArray(sortedAgentData) && sortedAgentData.map((agent, index) => (
              <ListItem key={index} style={{ backgroundColor: agent.status === "ACCEPT" ? green[100] : 'transparent' }}>
                <ListItemText 
                  primary={agent.agentId ? agent.agentId.fullName : ''} 
                  secondary={
                    <>
                      <div>{agent.agentId ? agent.agentId.email : ''}</div>
                      <div>{`${agent.agentId ? agent.agentId.countryCode : ''}${agent.agentId ? agent.agentId.contactNumber : ''}`}</div>
                    </>
                  } 
                />
                {agent.isSelected ? 
                  <CheckCircleIcon style={{ color: green[500] }} /> : 
                  <CancelIcon style={{ color: red[500] }} />
                }
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowModal(false)} color="primary">
            Sulje
          </Button>
        </DialogActions>
      </Dialog>

      {error && (
        <div>{error}</div>
      )}
    </>
  );
};


export default AgentDetailButton;
