import React from 'react';
import { AppBar as RAAppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {ToggleThemeButton } from 'react-admin';
import { MyUserMenu } from './MyLayout';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
});

const AppBar = (props) => {
  const classes = useStyles();
  return (
    <RAAppBar  toolbar={<ToggleThemeButton />} {...props} userMenu={<MyUserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <img src="/white.png" alt="logo" height="20px" />
      <span className={classes.spacer} />
      v2.23
    </RAAppBar>
  );
};

export default AppBar;
