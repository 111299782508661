import React, { useState, useEffect } from 'react';
import { List, Datagrid, FunctionField, DateField, TextField, useNotify, SimpleList } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AssignAgentDialog } from './AssignAgentDialog'; 
import { Button } from '@mui/material';
import {useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material';

const roundedImageStyle = {
  borderRadius: "50%",
  width: "50px",
  height: "50px",
  objectFit: "cover", 
};

export const AgencyList = props => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const [agencyDetails, setAgencyDetails] = useState(null);
  const token = localStorage.getItem('token');

  const handleClickOpen = async (id) => {
    try {
      const response = await fetch(`https://api.salaovi.fi/Admin/getAgencyDetails?agency_id=${id}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.statusCode === 200) {
        setAgencyDetails(data.data);
        setCurrentAgencyId(id); // Tallenna id tilaan
        setOpen(true);
      }
    } catch (error) {
      // Käsittely jos API-kutsu epäonnistuu
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemoveAgent = async (agentId) => {
    try {
      const response = await fetch('https://api.salaovi.fi/Admin/addAgentsToAgency', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          agencyId: currentAgencyId, // Käytä tilassa olevaa id:tä
          removeAgents: [agentId],
        }),
      });

      const result = await response.json();
      if (result.statusCode === 200) {
        notify('Agentti poistettu onnistuneesti');
            // Päivitä agencyDetails poistamalla kyseinen agentti
      setAgencyDetails(prevDetails => ({
        ...prevDetails,
        agents: prevDetails.agents.filter(agent => agent.agentId._id !== agentId)
      }));
      } else {
        notify('Virhe: agentin poisto ei onnistunut');
      }
    } catch (error) {
      notify('Virhe: agentin poisto ei onnistunut');
    }
  };
  

  const [assignAgentOpen, setAssignAgentOpen] = useState(false);
  const [currentAgencyId, setCurrentAgencyId] = useState(null);
  
  const handleAssignAgentClick = (id) => {
    setAssignAgentOpen(true);
    setCurrentAgencyId(id);  // Tallenna nykyinen agencyId
  };
  
  const closeAssignAgentDialog = () => {
    setAssignAgentOpen(false);
    setCurrentAgencyId(null); // Tyhjennä nykyinen agencyId
  };

  return (
    <List title="Välitysliikkeet" {...props} filter={{ type: 'AGENCY' }}>
    {isSmall ? (
      <SimpleList
      primaryText={record => record.companyName}
      secondaryText={record => record.city}
      tertiaryText={record => new Date(record.updatedAt).toLocaleDateString()}
      leftAvatar={record => record.companyImage}
    />
    ) : (
      <Datagrid rowClick="edit">
        <FunctionField label="Logo" render={record => <img style={roundedImageStyle} src={record.companyImage} alt="profiilikuva" width={50} />} />
        <TextField  label="Yritys" source="companyName" />
        <TextField  label="Puhelinnumero" source="contactNumber" />
        <TextField  label="Kaupunki" source="city" />
        <DateField  label="Päivitetty" source="updatedAt" />
        <TextField label="Osoite" source="address" />
        <FunctionField label="Välittäjät" render={record => <Button  variant="contained" color="primary" onClick={() => handleClickOpen(record.id)}>Välittäjät</Button>} />
        <FunctionField label="Lisää Välittäjiä" render={record => <Button  variant="contained" color="primary" onClick={() => handleAssignAgentClick(record.id)}>Lisää+</Button>} />

      </Datagrid>
  )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Välittäjät välitysliikkeessä</DialogTitle>
        <DialogContent>
          {agencyDetails ? (
            <div>
              {agencyDetails.agents && agencyDetails.agents.length > 0 ? (
                <ol>
                  {agencyDetails.agents.map((agent, index) => (
                    <li key={index} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                      <img 
                        src={agent.agentId.profilePicture} 
                        alt={agent.agentId.fullName} 
                        style={{ borderRadius: "50%", width: "50px", height: "50px", objectFit: "cover", marginRight: "15px" }}
                      />
                      <span>{agent.agentId.fullName}</span>
                      <Button  variant="contained" color="primary" style={{ marginLeft: 'auto' }} onClick={() => handleRemoveAgent(agent.agentId._id)}>Poista</Button>
                    </li>
                  ))}
                </ol>
              ) : (
                <h4>Ei välittäjiä toimistossa</h4>
              )}
            </div>
          ) : (
            <p>Ladataan tietoja...</p>
          )}
        </DialogContent>





      </Dialog>
      <AssignAgentDialog showDialog={assignAgentOpen} setDialog={closeAssignAgentDialog} agencyId={currentAgencyId} />

    </List>
  );
};
