import { defaultTheme } from 'react-admin';
import { indigo, pink, red } from '@material-ui/core/colors';
import { createTheme } from '@mui/material/styles'; // versio 5

const myTheme = createTheme({
    ...defaultTheme,
    palette: {
      primary: {
        main: '#04263f', // korvaa tämä haluamallasi HEX-värillä primaarivärinä
      },
      secondary: {
        main: '#04263f', // korvaa tämä haluamallasi HEX-värillä sekundaarivärinä
      },
      error: {
        main: '#f44336', // korvaa tämä haluamallasi HEX-värillä virhevärinä
      },
      background: {
        default: '#eef4f6', // Tämä on oletustaustaväri
        // Voit lisätä muita taustavärejä tarvittaessa
      },
    },
  typography: {
    fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Arial", "sans-serif"].join(","),
  },
  components: {
    ...defaultTheme.components,
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: '#8e969d !important', // Valikon kuvakkeiden väri
            minWidth:'40px !important'
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: '#8e969d !important', // Valikon kuvakkeiden väri
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: '#8e969d !important', // Vaihda tämä haluamallasi värillä
            textTransform: 'uppercase', //
            fontSize: '12px',
            fontWeight: '500',
            lineHeight:'2.3'
          },
        },
      },
      
    // Jos haluat mukauttaa sivupalkkia, voit lisätä sen määrittelyt tähän
    MuiDrawer: {
      styleOverrides: {
        docked: {
            // Olettaen, että 'MuiDrawer-docked' on luokka, jota haluat muokata
            backgroundColor: '#0a0a0a !important', // korvaa tämä haluamallasi värillä
            color: '#8e969d', // korvaa tämä haluamallasi värillä
           
          },
      },
      paper: {
        // Tämä tyyli vaikuttaa kiinnitettyyn sivupalkkiin
        backgroundColor: '#0a0a0a !important', // korvaa tämä haluamallasi värillä
        color: '#8e969d', // korvaa tämä haluamallasi värillä
        // Lisää muita tyylejä tarvittaessa
      },
   
    },
    // Voit jatkaa muiden komponenttien mukauttamista tarpeen mukaan
  },
});

export default myTheme;
