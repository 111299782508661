// Muokkauskomponentti nimeltä EditEnquiryButton.js
import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';

export const EditEnquiryButton = ({ record }) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(record || {});
  const token = localStorage.getItem('token');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    try {
      const {
        id,
        name,
        email,
        contactNumber,
        address,
        city,
        zipCode,
        size,
        comment,
      } = formData;
  
      const payload = {
        enqiryId: id,
        name,
        email,
        contactNumber,
        address,
        city,
        zipCode,
        size,
        comment,
      };
  
      const response = await fetch('https://api.salaovi.fi/Admin/updateEnquiry', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,  // Varmista, että token on saatavilla tässä scopessa
        },
        body: JSON.stringify(payload),
      });
  
      const result = await response.json();
      if (result.statusCode === 200) {
        setOpen(false);
        // Päivitä lista tässä, jos tarpeen
      }
    } catch (error) {
      console.error('Error updating enquiry:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
       <Button variant="contained" color="primary" onClick={handleClickOpen}>Muokkaa</Button>
       <Dialog open={open} onClose={handleClose}maxWidth="xs" fullWidth>
        <DialogTitle>Muokkaa Vertailua</DialogTitle>

        <DialogContent>
  <Box mb={2}>
    <TextField  fullWidth label="Nimi" name="name" value={formData.name || ''} onChange={handleChange} />
  </Box>
  <Box mb={2}>
    <TextField  fullWidth label="Sähköposti" name="email" value={formData.email || ''} onChange={handleChange} />
  </Box>
  <Box mb={2}>
    <TextField  fullWidth  label="Puhelinnumero" name="contactNumber" value={formData.contactNumber || ''} onChange={handleChange} />
  </Box>
  <Box mb={2}>
    <TextField  fullWidth label="Katuosoite" name="address" value={formData.address || ''} onChange={handleChange} />
  </Box>
  <Box mb={2}>
    <TextField  fullWidth label="Kaupunki" name="city" value={formData.city || ''} onChange={handleChange} />
  </Box>
  <Box mb={2}>
    <TextField  fullWidth  label="Postinumero" name="zipCode" value={formData.zipCode || ''} onChange={handleChange} />
  </Box>
  <Box mb={2}>
    <TextField  fullWidth label="Koko" name="size" value={formData.size || ''} onChange={handleChange} />
  </Box>
  <Box mb={2}>
    <TextField  fullWidth label="Kommentti" name="comment" value={formData.comment || ''} onChange={handleChange} />
  </Box>
  <Button  variant="contained" color="primary" onClick={handleSave}>Tallenna</Button>
</DialogContent>
      </Dialog>
    </div>
  );
};
