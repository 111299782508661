import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { useUpdate } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  active: {
    color:'#fff', 
    backgroundColor: '#4caf50',
    '&:hover': {
      backgroundColor: theme.palette.success.dark, // Tummennettu vihreä hover-tilassa
      color:'#fff',
    },
   
  },
  inactive: {
    backgroundColor: theme.palette.error.main, // Punainen väri ei-aktiiviselle tilalle
    color:'#fff',
    '&:hover': {
      backgroundColor: theme.palette.error.dark, // Tummennettu punainen hover-tilassa
      color:'#fff',
    },
 
  },
}));

const TogglebuttonIsActive = ({ record, resource }) => {
  const classes = useStyles();
  const [update] = useUpdate(resource, record.id, {}, record);

  const handleClick = () => {
    update({ payload: { data: { ...record, isActive: !record.isActive } } });
  };

  return (
    <Button 
      className={`${classes.button} ${record.isActive ? classes.active : classes.inactive}`}
      variant="contained"
      onClick={handleClick}
    >
      {record.isActive ? "Kyllä" : "Ei"}
    </Button>
  );
};

export default TogglebuttonIsActive;
