import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles

export const ContactEdit = () => {
    const token = localStorage.getItem('token');
    const [data, setData] = useState(null);
    const [viewAsHtml, setViewAsHtml] = useState(false);  

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://api.salaovi.fi/Admin/listContent', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json',
                        'authorization': token
                    },
                    body: 'type=CONTACT'
                });
                const result = await response.json();
                setData(result.data[0].text);
            } catch (error) {
                console.error('Virhe datan haussa:', error);
            }
        };
        fetchData();
    }, []);

    const updateData = async (textToUpdate) => {
        try {
            const formData = new URLSearchParams();
            formData.append('text', textToUpdate);
            formData.append('type', 'CONTACT');
    
            const response = await fetch('https://api.salaovi.fi/Admin/addContent', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'authorization': token
                },
                body: formData.toString()
            });
            const result = await response.json();
            // Voit lisätä koodia käsitelläksesi vastauksen tai päivittääksesi komponentin tilaa
        } catch (error) {
            console.error('Virhe datan päivityksessä:', error);
        }
    };

    return (
        <div>

<h1>Yhteydenottosivun muokkaus</h1>  {/* Otsikko lisätty tähän */}
            <button onClick={() => setViewAsHtml(!viewAsHtml)}>
                {viewAsHtml ? "Vaihda editoriin" : "Näytä HTML koodi"}
            </button>
    
            {viewAsHtml ? (
                <textarea
                value={data || ''}
                onChange={(e) => setData(e.target.value)}
                style={{ width: '100%', height: '400px' }}
            />
            ) : (
                <ReactQuill 
                    value={data || ''} 
                    onChange={setData} 
                    style={{  }}  // Muutetaan ReactQuill-editorin kokoa
                />
            )}
    
            <button onClick={() => updateData(data)}>Lähetä</button>
        </div>
    );
};

export default ContactEdit;
