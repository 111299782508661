import React from 'react';
import { Admin, Resource, ListGuesser, EditGuesser, CustomRoutes} from 'react-admin';
import MyLoginPage from './MyLoginPage'; 
import myDataProvider from './myDataProvider';
import authProvider from './authProvider';
import { AgentList } from './AgentList';
import { AgencyList } from './AgencyList';
import {UserList} from './UserList';
import { AgentEdit } from './AgentEdit';
import PostIcon from '@material-ui/icons/Book';
import UserIcon from '@material-ui/icons/People';
import MyLayout from './MyLayout';
import { EnquiryList, EnquiryShow } from './EnquiryList';
import { AgencysList } from './AgencysList';
import Dashboard from './Dashboard'; 
import MyMenu from './MyMenu'; 
import { SupportList } from './SupportList';
import { ContactEdit} from './contactEdit';

import { TermsEdit } from './termsEdit';
import {HelpEdit} from './HelpEdit'
import myTheme from './Theme';
import i18nProvider from './i8nProvider';
import CreateLinkPage from './key-account';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CreateEnquiryPage from './key-enquiry';
import { AgencyEdit } from './AgencyEdit';


const App = () => (


  <BrowserRouter>
  <Routes>
 
  <Route path="*" element={

  <Admin
theme={myTheme}
i18nProvider={i18nProvider}
      layout={MyLayout}
      title="Salaoven takaovi" 
      loginPage={MyLoginPage} 
      dataProvider={myDataProvider} 
      authProvider={authProvider}
      dashboard={Dashboard} // Asetetaan Dashboard-komponentti etusivuksi
      menu={MyMenu}
      darkTheme={{ palette: { mode: 'dark' } }}
    >


<CustomRoutes>
<Route path="/ContactEdit" element={<ContactEdit />} options={{ label: 'Muokkaa yhteystietosivua' }} exact />

<Route path="/termsedit" element={<TermsEdit />} options={{ label: 'Muokkaa yhteystietosivua' }} exact />

<Route path="/key-account" element={<CreateLinkPage />} options={{ label: 'Key Account' }} exact />
<Route path="/key-enquiry" element={<CreateEnquiryPage />} options={{ label: 'avainasiakas' }} exact />
<Route path="/helpedit" element={<HelpEdit />} options={{ label: 'Muokkaa yhteystietosivua' }} exact />

        </CustomRoutes>


<Resource 
name="listAgents"
options={{ label: 'Kiinteistönvälittäjät' }}
icon={UserIcon}
list={AgentList} 
edit={AgentEdit}
/>


  <Resource
    name="listEnquiry"
    options={{ label: 'Vertailut' }}
    list={EnquiryList}
    icon={PostIcon}
    show={EnquiryShow}
    
  />

<Resource 
name="listUsers"
options={{ label: 'Asiakkaat' }}
icon={UserIcon}
list={UserList} 
edit={EditGuesser}
/>
<Resource 
name="listAgencies2"
options={{ label: 'Välitysliikkeet' }}
icon={UserIcon}
list={AgencyList} 


/>
<Resource
      name="listAgencies"
      options={{ label: 'Liikkeet' }}
      list={AgencysList}
      edit={EditGuesser}
    />



<Resource name="supportListing" list={SupportList} />
  {/* ... Lisää resursseja samalla tavalla */}
</Admin>
} />
 </Routes>
    </BrowserRouter>

);

export default App;