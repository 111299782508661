import * as React from 'react';
import { useLogout, useTranslate } from 'react-admin';
import { MenuItem, ListItemIcon } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

const CustomLogout = ({ onClick }) => {
    const logout = useLogout();
    const translate = useTranslate();
    const handleClick = () => {
        logout();
        if (onClick) {
            onClick();
        }
    };
    return (
        <MenuItem onClick={handleClick}>
            <ListItemIcon>
                <LogoutIcon />
            </ListItemIcon>
            Kirjaudu Ulos
        </MenuItem>
    );
};

export default CustomLogout;

