// in src/authProvider.js
const authProvider = {
  login: ({ email, password }) => {
    const request = new Request('https://api.salaovi.fi/Admin/Login', {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ data }) => {
        if (data.scope !== 'ADMIN') { // Tarkista, että scope on 'ADMIN'
          throw new Error('Not allowed');
        }
        localStorage.setItem('token', data.accessToken); // Tallenna accessToken
        localStorage.setItem('scope', data.scope); // Tallenna scope paikalliseen muistiin
      })
      .catch(() => {
        throw new Error('Network error or not allowed')
      });
  },
  checkAuth: () => {
    return localStorage.getItem('token') && localStorage.getItem('scope') === 'ADMIN'
      ? Promise.resolve()
      : Promise.reject();
  },
  getPermissions: () => {
    // Required for the authentication to work
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('scope'); // Poista myös scope
    return Promise.resolve();
  },
  // ...
};

export default authProvider;
