import * as React from 'react';
import { DashboardMenuItem, MenuItemLink } from 'react-admin';
import BookIcon from '@material-ui/icons/Book';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import UserIcon from '@material-ui/icons/People';
import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import WorkIcon from '@mui/icons-material/Work';
import AssignmentIcon from '@mui/icons-material/Assignment';
import VillaIcon from '@mui/icons-material/Villa';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import CustomLogout from './CustomLogout'; 
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import ContactMailIcon from '@mui/icons-material/ContactMail';

const MyMenu = ({ onMenuClick, logout }) => {
    const [openKohteet, setOpenKohteet] = React.useState(true);
    const [openLiikkeet, setOpenLiikkeet] = React.useState(true);
    const [openHallinta, setOpenHallinta] = React.useState(true);
  
    const handleClickKohteet = () => {
      setOpenKohteet(!openKohteet);
    };
  
    const handleClickLiikkeet = () => {
      setOpenLiikkeet(!openLiikkeet);
    };
  
    const handleClickHallinta = () => {
      setOpenHallinta(!openHallinta);
    };
  return (
    <div>
      <DashboardMenuItem primaryText="Hallintapaneeli" onClick={onMenuClick} />
      <MenuItemLink to="/listEnquiry" primaryText="Kaikki Vertailut" leftIcon={<AssignmentIcon />} onClick={onMenuClick} />
      <MenuItemLink to="/key-account" primaryText="Key Account manager" leftIcon={<ContactMailIcon />} onClick={onMenuClick} />

      <ListItem button onClick={handleClickKohteet}>


        {openKohteet ? <ExpandMore /> : <ExpandLess />}
        <ListItemText primary="Käyttäjät" />
      </ListItem>
      <Collapse in={openKohteet} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <MenuItemLink to="/listAgents" primaryText="Kiinteistönvälittäjät" leftIcon={<WorkIcon />} onClick={onMenuClick} />


          <MenuItemLink to="/listUsers" primaryText="Vertailuasiakkaat" leftIcon={<UserIcon />} onClick={onMenuClick}/>
          <MenuItemLink to="/supportListing" primaryText="Palaute" leftIcon={<SupportAgentIcon />} onClick={onMenuClick} />

        </List>
      </Collapse>

      <ListItem button onClick={handleClickLiikkeet}>
      {openLiikkeet ? <ExpandMore /> : <ExpandLess />}
        <ListItemText primary="Toimistot" />

      </ListItem>
      <Collapse in={openLiikkeet} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <MenuItemLink to="/listAgencies2" primaryText="Välitysliikkeet" leftIcon={<CorporateFareIcon />} onClick={onMenuClick} />
          <MenuItemLink to="/listAgencies" primaryText="Toimistot" leftIcon={<VillaIcon />} onClick={onMenuClick} />
        </List>
      </Collapse>

      <ListItem button onClick={handleClickHallinta}>
      {openHallinta ? <ExpandMore /> : <ExpandLess />}
<ListItemText primary="Apin Sivut" />

</ListItem>
<Collapse in={openHallinta} timeout="auto" unmountOnExit>
<List component="div" disablePadding>
  <MenuItemLink to="/ContactEdit" primaryText="Yhteydenotto" leftIcon={<ContactPhoneIcon />} onClick={onMenuClick} />
  <MenuItemLink to="/termsedit" primaryText="Käyttöehdot" leftIcon={<PrivacyTipIcon />} onClick={onMenuClick} />
  <MenuItemLink to="/helpedit" primaryText="Tukisivut" leftIcon={<HelpCenterIcon />} onClick={onMenuClick} />
</List>
</Collapse>

<CustomLogout onClick={onMenuClick} />
      {logout}
    </div>
  );
};

export default MyMenu;

