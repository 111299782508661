import React, { useState, useEffect } from 'react';
import axios from 'axios';

const MoneyCount = () => {
    const [enquiryCount, setEnquiryCount] = useState(0);
    const pricePerEnquiry = 200; // Hinta per tiedustelu

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token'); 
                const response = await axios.post('https://api.salaovi.fi/Admin/dashBoard', {}, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json',
                        'Authorization': token
                    }
                });

                if (response.data && response.data.data && response.data.data.totalEnquiry) {
                    setEnquiryCount(response.data.data.totalEnquiry);
                }
            } catch (error) {

            }
        };

        fetchData();
    }, []);

    // Lasketaan kokonaissumma
    const totalAmount = (enquiryCount * pricePerEnquiry).toLocaleString('fi-FI', { minimumFractionDigits: 0, maximumFractionDigits: 2 });

    return (
        <div>
            <h4>Elinikäinen tulos</h4>
            <h3>{totalAmount}€</h3> {/* Pyöristetään kahteen desimaaliin */}
        </div>
    );
};

export default MoneyCount;
