import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  BooleanInput,
  ImageInput,
  ImageField,
  useRefresh,
  useRedirect,
  SelectInput,
  Button, 
  useRecordContext ,
  FunctionField
} from "react-admin";
import ProfileSidebar from "./ProfileSidebar";
import React, { useEffect, useState } from "react";
import myDataProvider from "./myDataProvider";
import { useParams } from "react-router-dom";
import AddFundsButton from './AddFundsButton';

const CustomBackButton = () => {
  const redirect = useRedirect();

  const handleBack = () => {
    redirect('..'); // Palaa takaisin listausnäkymään
  };

  return <Button onClick={handleBack}>Palaa listanäkymään</Button>;
};

const UserTitle = () => {
  const record = useRecordContext();
  return <span>Välittäjä {record ? `${record.fullName}` : ''}</span>;
};

const agencies = [
  { id: "6080001e12ac3e0df4a35619", name: "En työskentele" },
  { id: "6075a6fff368204a35dc7ef0", name: "RE/MAX" },
  { id: "6075a70cf368204a35dc7ef2", name: "Huom!" },
  { id: "6075a728f368204a35dc7ef6", name: "Kiinteistömaailma" },
  { id: "6075a717f368204a35dc7ef3", name: "Roof Group" },
  { id: "6075a731f368204a35dc7ef7", name: "Huoneistokeskus" },
  { id: "6075a721f368204a35dc7ef5", name: "Sp-Koti" },
  { id: "6075a71cf368204a35dc7ef4", name: "Aninkainen" },
  { id: "6075a739f368204a35dc7ef8", name: "Glik" },
  { id: "6075a706f368204a35dc7ef1", name: "Kahdeksas päivä" },
  { id: "6075a740f368204a35dc7ef9", name: "Solid House" },
  { id: "6076a4457c6ce14bfa9be56b", name: "Kotijoukkue" },
  { id: "6076a4fc7c6ce14bfa9be56c", name: "Bo LKV" },
  { id: "652d332b1467be0fbc71f0b0", name: "Neliöt Liikkuu" }
];


const handleRefund = async (userId, amount, customerId) => {
  console.log("customerId:", customerId);
  const token = localStorage.getItem("token"); // Hae token local storagelta.
  const response = await fetch(
    "https://api.salaovi.fi/User/refund",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `Bearer ${token}`, // Varmista, että token on oikein muodostettu.
      },
      body: `amount=${amount}&id=${userId}&customerId=${customerId}`,  // Muuta body muotoa vastaamaan CURL-komentoa.
    }
  );

  if (response.ok) {
    console.log("Refund successful");
    window.location.reload(); // Lataa sivu uudelleen
  } else {
    console.error("Refund failed");
  }
};

export const AgentEdit = (props) => {
  const { id } = useParams();
  const [record, setRecord] = useState(null);
  const refresh = useRefresh();
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await myDataProvider.getOne("listAgents", { id });
        setRecord(response.data); // Päivitetty datan asettaminen
      } catch (error) {
        console.error("API Error:", error);
      }
    };

    fetchData();
  }, [id]);



  useEffect(() => {
    if (!record) {
      refresh();
    }
  }, [record, refresh]);

  if (!record) return <div>Ladataan...</div>;

  const transformBooleanToValue = value => value ? "nocomission" : "null";
const transformValueToBoolean = value => value === "nocomission";



  // Muokkaa tietoja record-objektista
  const { fullName, address, profilePicture, JobTitle, companyName, companyImage } =
    record;
  return (
    <div style={{ display: "flex" }}>
      <ProfileSidebar
        fullName={fullName || "Tuntematon"} // Käytä dataa tai oletusarvoa
        address={address || "N/A"}
        companyName={companyName || "N/A"}
        JobTitle={JobTitle || "N/A"}
        companyImage={
          companyImage||
          "https://secure.gravatar.com/avatar/08c2a3635dca1dfc489e617d9e5d7026?s=64&d=mm&r=g"
        }
        profileImage={
          profilePicture ||
          "https://secure.gravatar.com/avatar/08c2a3635dca1dfc489e617d9e5d7026?s=64&d=mm&r=g"
        }
      />

      <div style={{ flexGrow: 1 }}>
        {record ? (
          <Edit title={<UserTitle />} {...props} >
            
            <TabbedForm>
            <FormTab label="Henkilökohtaiset tiedot">
  <TextInput label="Koko nimi" source="fullName" style={{ width: "70%" }} />
  <TextInput label="Sähköposti" source="email" style={{ width: "70%" }} />
  <TextInput label="Maakoodi" source="countryCode" style={{ width: "70%" }} />
  <TextInput label="Puhelinnumero" source="contactNumber" style={{ width: "70%" }} />
  <TextInput label="Osoite" source="address" style={{ width: "70%" }} />
  <TextInput label="Kaupunki" source="city" style={{ width: "70%" }} />
  <BooleanInput label="Superagentti" source="superAgent" />
  <BooleanInput label="Lomamode" source="vacationMode" />


<BooleanInput
  label="Komissiovapaa sopimus"
  source="plan"
  parse={transformBooleanToValue}
  format={transformValueToBoolean}
/>
</FormTab>
<FormTab label="Yrityksen tiedot">
  <TextInput label="Yrityksen nimi" source="companyName" style={{ width: "70%" }} />
  <ImageInput
    label="Yrityksen kuva"
    source="companyImage"
    style={{ width: "70%" }}
    accept="image/*"
    placeholder={<p>Vedä kuva tähän</p>}
  >
    <ImageField source="src" title="title" />
  </ImageInput>

  <ImageInput
    label="Profiilikuva"
    source="profilePicture"
    style={{ width: "70%" }}
    accept="image/*"
    placeholder={<p>Vedä kuva tähän</p>}
  >
    <ImageField source="src" title="title" />
  </ImageInput>
  <TextInput label="Työnimike" source="jobTitle" style={{ width: "70%" }} />
  <TextInput label="Osoite" source="address" style={{ width: "70%" }} />
  <TextInput label="Aloitti välittäjänä" source="startWorking" style={{ width: "70%" }} />
 
  <SelectInput
    source="agency"
    label="Työskenteleekö näissä"
    choices={agencies}
    optionText="name"
    optionValue="id"
  />

  <BooleanInput label="Kilpailee toimiston kanssa?" source="compete_with_agency" />
  <TextInput label="Profiililinkki" defaultValue="Oikotien profiililinkki" source="profile_link" style={{ width: "70%" }} />
</FormTab>

              <FormTab label="Maksut">
              <AddFundsButton userId={record.id} />
              <div>
                  <h2 style={{ fontSize: "20px" }}>Maksutavat</h2>
                </div>
                <table style={{ width: '400px', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th style={{  padding: '8px' }}></th>
          <th style={{padding: '8px' }}></th>
          <th style={{ padding: '8px' }}></th>
          <th style={{padding: '8px' }}></th>
        </tr>
      </thead>
      <tbody>
      {record.data4Data &&
                        Object.keys(record.data5Data).map((key, index) => {
                          const item = record.data5Data[key];
                          return (
                            <tr
                              key={index}
                              style={{ borderBottom: "1px solid #ccc" }}
                            >
            <td style={{ padding: '8px' }}>{item.brand}</td>
            <td style={{ padding: '8px' }}>{item.exp_month}/{item.exp_year}</td>
            <td style={{ padding: '8px' }}>{item.funding}</td>
            <td style={{ padding: '8px' }}>{item.last4}</td>
          </tr>
           );
        })}
      </tbody>
    </table>
                <div>
                  <h2 style={{ fontSize: "20px" }}>Maksuhistoria</h2>
                </div>
                <div style={{ overflowX: "auto" }}>
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                      <tr style={{ borderBottom: "2px solid #ccc" }}>
                        <th style={{ padding: "10px", textAlign: "left" }}>
                          Asiakas
                        </th>
                        <th style={{ padding: "10px", textAlign: "left" }}>
                          Summa
                        </th>
                        <th style={{ padding: "10px", textAlign: "left" }}>
                          Saldo
                        </th>
                        <th style={{ padding: "10px", textAlign: "left" }}>
                          Tapahtuma
                        </th>
                        <th style={{ padding: "10px", textAlign: "left" }}>
                          Viite
                        </th>
                        <th style={{ padding: "10px", textAlign: "left" }}>
                          Päiväys
                        </th>
                        <th style={{ padding: "10px", textAlign: "left" }}>
                          Hyvitys
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {record.data4Data &&
                        Object.keys(record.data4Data).map((key, index) => {
                          const item = record.data4Data[key];
                          return (
                            <tr
                              key={index}
                              style={{ borderBottom: "1px solid #ccc" }}
                            >
                              <td style={{ padding: "10px" }}>
                                {item.customerId
                                  ? item.customerId.fullName
                                  : "SALAOVI"}
                              </td>
                              <td style={{ padding: "10px" }}>
  {item.purpose === 'reversal' ? '+' : '-'}{item.amount}€
</td>
                              <td style={{ padding: "10px" }}>
                                {item.balance}€
                              </td>
                              
                              <td style={{ padding: "10px" }}>
                                {item.purpose}
                              </td>
                              <td style={{ padding: "10px" }}>
                                {item.reference}
                              </td>
                              <td style={{ padding: "10px" }}>
                                {new Date(item.updatedAt).toLocaleDateString(
                                  "fi-FI",
                                  {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                  }
                                )}
                              </td>
                              <td style={{ padding: "10px" }}>
                                <Button variant="contained" color="primary"
                                  onClick={(event) => {
                                    event.preventDefault(); // Estä oletustapahtuma täällä.
                                    handleRefund(item.userId, item.amount, item.customerId._id);
                                  }}
                                >
                                  HYVITÄ
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </FormTab>

               <FormTab label="Valikoituneet">
              <h2>Valikoituneet vertailut</h2>
                {record.data3Data &&
                
                  Object.keys(record.data3Data)
                    .filter((key) => record.data3Data[key].isSelected === true)
                    .map((key, index) => {
                      const item = record.data3Data[key];
                      return (
                        <React.Fragment key={index}>
                          <div
                            style={{
                              padding: "10px",
                              width: "100%",
                              border: "1px solid #ccc",
                              marginBottom: "10px",
                              display: "flex",
                            }}
                          >
                            <div style={{ flexShrink: 0 }}>
                              {item.enquiryId.images &&
                              item.enquiryId.images.length > 0 ? (
                                item.enquiryId.images.map(
                                  (image, imageIndex) => (
                                    <div
                                      key={imageIndex}
                                      style={{ padding: "10px" }}
                                    >
                                      <img
                                        src={image}
                                        alt={`Kuva ${imageIndex + 1}`}
                                        style={{
                                          maxWidth: "200px",
                                          maxHeight: "200px",
                                        }}
                                      />
                                    </div>
                                  )
                                )
                              ) : (
                                <div style={{ padding: "10px" }}>
                                  <img
                                    src="https://www.salaovi.fi/wp-content/uploads/2019/01/shutterstock_731196583.jpg"
                                    alt="Oletuskuva"
                                    style={{
                                      maxWidth: "200px",
                                      maxHeight: "200px",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <div style={{ marginLeft: "20px" }}>
                              <div>
                                <h3>{item.enquiryId.address}</h3>
                              </div>
                              <FunctionField
                                label="Päivämäärä"
                                render={() => {
                                  if (!item.enquiryId.timeStamp)
                                    return "Ei aikaleimaa";
                                  const date = new Date(
                                    Number(item.enquiryId.timeStamp)
                                  );
                                  if (isNaN(date.getTime()))
                                    return "Virheellinen päivämäärä";
                                  return date.toLocaleString();
                                }}
                              />
                              <div>
                                <strong>Status:</strong> {item.status}
                              </div>
                              <div>
                                <strong>Asiakas:</strong> {item.enquiryId.name}
                              </div>

                              <div>
                                <strong>Puhelin:</strong> +358
                                {item.enquiryId.contactNumber}
                              </div>
                              <div>
                                <strong>Sähköposti:</strong>{" "}
                                {item.enquiryId.email}
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
              </FormTab> 

              <FormTab label="Myytävät kohteet">
                {record.data2Data &&
                  record.data2Data.map((item, index) => (
                    <React.Fragment key={index}>
                      <div
                        style={{
                          padding: "10px",
                          width: "400px",
                          border: "1px solid #ccc",
                          marginBottom: "10px",
                        }}
                      >
                        <div>
                          {item.images &&
                            item.images.map((image, imageIndex) => (
                              <div key={imageIndex} style={{ padding: "10px" }}>
                                <a
                                  href={image}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={image}
                                    alt={`Kuva ${imageIndex + 1}`}
                                    style={{
                                      maxWidth: "200px",
                                      maxHeight: "200px",
                                    }}
                                  />
                                </a>
                              </div>
                            ))}
                        </div>
                        <div>
                          <strong>Osoite:</strong> {item.address}
                        </div>
                        <div>
                          <strong>Status:</strong> {item.status}
                        </div>
                        <div>
                          <strong>Asiakkaan Nimi:</strong> {item.clientName}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
              </FormTab>
              <FormTab label="Oikotien kohteet">
  {record.data6Data &&
    record.data6Data.map((item, index) => (
      <React.Fragment key={index}>
        <div
          style={{
            padding: "10px",
            width: "400px",
            border: "1px solid #ccc",
            marginBottom: "10px",
          }}
        >
          {/* Linkki ilmoitussivulle */}
          <div>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              Katso ilmoitus
            </a>
          </div>
          <div>
            <strong>Osoite:</strong> {item.address}
          </div>
          <div>
            <strong>Alue:</strong> {item.district}
          </div>
          <div>
            <strong>Kaupunki:</strong> {item.city}
          </div>
          {/* Lisätään viimeksi päivitetty tieto */}
          <div>
            <strong>Viimeksi päivitetty:</strong> {new Date(item.lastUpdated).toLocaleDateString()}
          </div>
        </div>
      </React.Fragment>
    ))}
</FormTab>

            </TabbedForm>
            <CustomBackButton />
          </Edit>
          
        ) : (
          <div><h4>Ladataan välittäjän tietoja...</h4></div>
        )}
      </div>
    </div>
  );
};
