import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AgentCount = () => {
    const [agentCount, setAgentCount] = useState(0); 

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token'); 
                const response = await axios.post('https://api.salaovi.fi/Admin/dashBoard', {}, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${token}` 
                    }
                });

                if (response.data && response.data.data && response.data.data.totalAgent) {
                    setAgentCount(response.data.data.totalAgent);
                }
            } catch (error) {
  
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <h4>Välittäjiä yhteensä</h4>
            <h3>{agentCount}</h3>
        </div>
    );
};

export default AgentCount;
