import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import myDataProvider from './myDataProvider';  // Varmista, että polku on oikein

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    // Voit lisätä muita tyyliattribuutteja tarpeen mukaan
  },
  open: {
    backgroundColor: theme.palette.success.main, // Vihreä väri avoimelle tilalle
    color:'#fff',
    '&:hover': {
      backgroundColor: theme.palette.success.dark, // Tummennettu vihreä hover-tilassa
      color:'#fff',
    },
  },
  closed: {
    backgroundColor: theme.palette.error.main, // Punainen väri suljetulle tilalle
    color:'#fff',
    '&:hover': {
      backgroundColor: theme.palette.error.dark, // Tummennettu punainen hover-tilassa
      color:'#fff',
    },
  },
}));

const ToggleActiveButton = ({ record }) => {
  const classes = useStyles();
  const [isBlocked, setIsBlocked] = useState(record.isBlocked);
  const resource = 'manageUsers';

  const handleClick = (e) => {
    e.stopPropagation();  
    const newStatus = !isBlocked;
    const params = {
      id: record._id,
      data: {
        status: newStatus,
        id: record._id
      }
    };

    myDataProvider.update(resource, params)
      .then(response => {

        setIsBlocked(newStatus);
      })
      .catch(error => {
 
      });
  };

  return (
    <Button 
      className={`${classes.button} ${isBlocked ? classes.closed : classes.open}`}
      variant="contained"
      onClick={handleClick}
    >
      {isBlocked ? "Suljettu" : "Auki"}
    </Button>
  );
};

export default ToggleActiveButton;

