import * as React from "react";
import {
    List,
    Datagrid,
    FunctionField,
    TextField,
    TextInput,
    Filter,
    Pagination,
    SimpleList,
    SimpleShowLayout,
    Show, Button, TopToolbar
} from "react-admin";
import ConfirmActions from "./ConfirmActions"; //
import AgentDetailButton from "./AgentDetailButton";
import { EditEnquiryButton } from "./EditEnquiryButton";
import { useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';

const ListActions = ({ setFilter, setListKey, filter }) => {
    const handleToggleStatus = () => {
        setFilter(prev => {
            // Jos status on jo CLOSED, vaihdetaan se OPEN:ksi
            if (prev.status === 'CLOSED') {
                return { ...prev, status: 'OPEN' };
            } else {
                // Muulloin asetetaan status CLOSED:ksi
                return { ...prev, status: 'CLOSED' };
            }
        });
        setListKey(prevKey => prevKey + 1); // Pakotetaan List-komponentti päivittymään
    };

    const handleShowWon = () => {
        setFilter({ ...filter, isAccepted: true });
    };

    return (
        <TopToolbar>
            <Button onClick={handleToggleStatus} label="|||" />
            <Button onClick={handleShowWon} label="Voitetut" />
        </TopToolbar>
    );
};



const AgentFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Haku" source="searchTerm" alwaysOn />
    </Filter>
);

const roundedImageStyle = {
    width: "100px",
    objectFit: "cover",
};
const simplelistimage= {
    width: "100%",
    objectFit: "cover",
};

export const EnquiryShow = (props) => (
    
    <Show {...props}>
      <SimpleShowLayout>
      <FunctionField
                render={(record) => (
                    <img
                        style={simplelistimage}
                        src={
                            Array.isArray(record.images) && record.images.length > 0
                                ? record.images[0]
                                : "https://www.salaovi.fi/wp-content/uploads/2018/11/architecture-1836070_1920.jpg"
                        }
                        alt="kuva"
                        width={50}
                    />
                )}
            />
        <TextField source="name" label="Nimi" />
        <TextField source="city" label="Kaupunki" />
        <TextField label="Katuosoite" source="address" />

        <FunctionField
          label="Asunnon koko"
          render={(record) =>
            `${record.size }m2`
          }
        />
        <FunctionField
          label="Puhelinnumero"
          render={(record) =>
            `${record.countryCode || '0'}${record.contactNumber || ''}`
          }
        />
        <TextField source="email" label="Sähköposti" />
        <FunctionField
          label="Muokkaa"
          render={(record) => <EditEnquiryButton record={record} />}
        />

         <FunctionField
                label="Agentin tiedot"
                render={(record) => <AgentDetailButton record={record} />}
            />
                    <FunctionField
          label="Tila"
          render={(record) => <ConfirmActions record={record} />}
        />
        <FunctionField
                label="Status"
                render={(record) => {
                    switch (record.status) {
                        case "OPEN":
                            return "Käynnissä";
                        case "CLOSED":
                            return "Päättynyt";
                        default:
                            return record.status; // Palauttaa alkuperäisen arvon, jos se ei ole 'OPEN' tai 'CLOSED'
                    }
                }}
            />
      </SimpleShowLayout>
    </Show>
  );

  export const EnquiryList = (props) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    // Alustetaan suodatin näyttämään oletuksena vain OPEN-tilassa olevat kohteet.
    const [filter, setFilter] = React.useState({ status: 'OPEN' });
    const [listKey, setListKey] = React.useState(0);

    // Päivitetään ListActions propsit sisältämään myös filter-tiedon
    const listActionsProps = { setFilter, setListKey, filter };

    return (
        <List
            key={listKey} // Tämä pakottaa List-komponentin päivittymään, kun listKey muuttuu
            perPage={25}
            filters={<AgentFilter />}
            title="Vertailut"
            {...props}
            filter={filter} // Käytä tilassa määriteltyä suodatinta
            actions={<ListActions {...listActionsProps} />}
            pagination={<Pagination rowsPerPageOptions={[10, 25, 50]} />}
        >
      
        {isSmall ? (
          <SimpleList
            primaryText={record => record.name}
            secondaryText={record => record.city}
            tertiaryText={record => new Date(Number(record.timeStamp)).toLocaleString('fi-FI', {
                day: '2-digit',
                month: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
              
            linkType={(record) => (record.canEdit ? 'edit' : 'show')}
            leftAvatar={record =>
              Array.isArray(record.images) && record.images.length > 0
                ? record.images[0]
                : "https://www.salaovi.fi/wp-content/uploads/2018/11/architecture-1836070_1920.jpg"
            }
          />
        ) : (
            <Datagrid
            sx={{
                "& .column-title": {
                    maxWidth: "16em",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                },
                "& .column-commentable": {
                    maxWidth: "4em",
                },
                // Lisää tyylejä tarvittaessa
            }}
            rowSx={record => ({
                backgroundColor: record.isAccepted ? '#e0ffe0' : '', // Vihreä tausta, jos isAccepted on true
            })}
          >
            <FunctionField
                label="Kuva"
                render={(record) => (
                    <img
                        style={roundedImageStyle}
                        src={
                            Array.isArray(record.images) && record.images.length > 0
                                ? record.images[0]
                                : "https://www.salaovi.fi/wp-content/uploads/2018/11/architecture-1836070_1920.jpg"
                        }
                        alt="kuva"
                        width={50}
                    />
                )}
            />

            <TextField label="Nimi" source="name" />
            <FunctionField
                label="Puhelinnumero"
                render={(record) =>
                    `${record.countryCode || "0"}${record.contactNumber || ""}`
                }
            />
            <TextField label="Kaupunki" source="city" />
            <TextField label="Katuosoite" source="address" />
            <FunctionField
          label="Asunnon koko"
          render={(record) =>
            `${record.size }m2`
          }
        />
            <FunctionField
                label="Päivämäärä"
                render={(record) => {
                    if (!record.timeStamp) return "Ei aikaleimaa";
                    const date = new Date(Number(record.timeStamp));
                    if (isNaN(date.getTime())) return "Virheellinen päivämäärä";
                    return date.toLocaleString();
                }}
            />

            <FunctionField
                label="Muokkaa"
                render={(record) => <EditEnquiryButton record={record} />}
            />
            <FunctionField
                label="Agentin tiedot"
                render={(record) => <AgentDetailButton record={record} />}
            />
            <FunctionField
                label="Tila"
                render={(record) => <ConfirmActions record={record} />}
            />
            <FunctionField
                label="Status"
                render={(record) => {
                    switch (record.status) {
                        case "OPEN":
                            return "Käynnissä";
                        case "CLOSED":
                            return "Päättynyt";
                        default:
                            return record.status; // Palauttaa alkuperäisen arvon, jos se ei ole 'OPEN' tai 'CLOSED'
                    }
                }}
            />
        </Datagrid>
      )}
      </List>
    );
  };
  
