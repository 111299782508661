// in src/MyLayout.js
import * as React from 'react';
import { forwardRef } from 'react';
import { Layout, UserMenu, useLogout, MenuItemLink } from 'react-admin';
import { MenuItem } from '@mui/material';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import AppBar from './AppBar'; 
import WorkIcon from '@mui/icons-material/Work';
import myTheme from './Theme';


// It's important to pass the ref to allow Material UI to manage the keyboard navigation
const MyLogoutButton = forwardRef((props, ref) => {
    const logout = useLogout();
    const handleClick = () => logout();
    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
            // It's important to pass the props to allow Material UI to manage the keyboard navigation
            {...props}
        >
            <ExitIcon />  
            Kirjaudu Ulos
        </MenuItem>
    );
});

export const MyUserMenu = () => (
    <UserMenu>
        <MenuItemLink to="/key-account" primaryText="Key Account manager" leftIcon={<WorkIcon />}/>
        <MyLogoutButton />
    </UserMenu>
);

const MyAppBar = () => <AppBar userMenu={<UserMenu />} />;

const MyLayout = (props) => (
    <Layout {...props} appBar={MyAppBar} theme={myTheme} />
);

export default MyLayout;