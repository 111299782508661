import { fetchUtils } from 'ra-core';
import simpleRestProvider from 'ra-data-simple-rest';
import axios from 'axios';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
}

const apiUrl = 'https://api.salaovi.fi/';
const dataProvider = simpleRestProvider(apiUrl, httpClient);



export const apiEndpoints = {
    login: 'Admin/Login',
    logout: 'Admin/logout',
    dashBoard: 'Admin/dashBoard',
    listAgents: 'Admin/ListUsers',
    listAgencies2: 'Admin/ListUsers',
    listUsers: 'Admin/ListUsers',
    manageUsers: 'Admin/blockUser',
    listEnquiry: 'Admin/ListEnquiry',
    listProfileData: 'Admin/listProfileData',
    crawlListingData: 'Admin/crawlListingData',
    approveEnquiry: 'Admin/approveEnquiry',
    getAgencyDetails: 'Admin/getAgencyDetails',
    listAgentsWithNoAgency: 'Admin/listAgentsWithNoAgency',
    changePassword: 'Admin/changePassword',
    updateContent: 'Admin/addContent',
    listContent: 'Admin/listContent',
    listBanner: 'Admin/listBanner',
    deleteBanner: 'Admin/deleteBanner',
    getCustomerDetails: 'Admin/getCustomerDetails',
    editCustomerProfile: 'Admin/editCustomerProfile',
    updateEnquiry: 'Admin/updateEnquiry',
    addAgentsToAgency: 'Admin/addAgentsToAgency',
    addBanner: 'Admin/addBanner',
    addFundsToWallet: 'Admin/addFundsToWallet',
    listSelectedAgent: 'Admin/listSelectedAgent',
    agentListing: 'Admin/agentListing',
    agentProposals: 'User/getAnAgentsProposals',
    agentUserDatails: 'User/getUserSideBarDetails',
    getAnAgentsProposals: 'User/getAnAgentsProposals',
    refund: 'User/refund',
    Adminviewtransactionhistory: 'User/AdminViewTransactionHistory',
    supportListing: 'Admin/supportListing',
    makeSuperAgent: 'Admin/makeSuperAgent',
    addAgency: 'Admin/addAgency',
    editAgency: 'Admin/editAgency',
    editAgentradius: 'Admin/editAgentradius',
    listAgencies: 'Admin/listAgencies',
    deleteAgency: 'Admin/deleteAgency',
    getAgentRadius: 'Admin/getAgentRadius',
    getAgentListing: 'Admin/getAgentListing',
    getAgentDetails: 'Admin/getAgentDetails',
    editAgentCompanyDetails: 'Admin/editAgentCompanyDetails',
    editAgentAccountSettings: 'Admin/editAgentAccountSettings'
};
const addPrefixToKeys = (obj, prefix) => {
  return Object.keys(obj).reduce((acc, key) => {
    acc[`${prefix}${key}`] = obj[key];
    return acc;
  }, {});
};

export default {
  ...dataProvider,

  getList: async (resource, params) => {
    try {
      const { page, perPage } = params.pagination;
      const type = params.filter ? params.filter.type : null;
      const searchTerm = params.filter ? params.filter.searchTerm : null;
      const isBlocked = resource === "listAgents" && params.filter.hasOwnProperty("isBlocked")
      ? params.filter.isBlocked
      : null;
      
      let url = `${apiUrl}${apiEndpoints[resource]}`;
      const urlParams = new URLSearchParams();

      if (type) {
        urlParams.append("type", type);
      }

      if (searchTerm) {
        urlParams.append("searchTerm", searchTerm);
      }

      if (isBlocked !== null) {
        urlParams.append("isBlocked", isBlocked);
      }

      urlParams.append("page", page);
      urlParams.append("perPage", perPage);

      if (resource === "supportListing") {
        // Käytä PUT-metodia, kun kyseessä on 'support' resurssi

        const options = {
          method: "POST",
          body: JSON.stringify({ ...params.filter, page, perPage }),
          headers: new Headers({ "Content-Type": "application/json" }),
        };

        const { json } = await httpClient(url, options);

        if (
          !json ||
          !json.data ||
          !Array.isArray(json.data.data) ||
          typeof json.data.total_count !== "number"
        ) {
          console.error("API call returned invalid data");
          return;
        }

        const total = json.data.total_count;
        const data = json.data.data.map((item) => ({
          ...item,
          id: item._id,
        }));

        return { data, total };
      } else {
        if (urlParams.toString()) {
          url = `${url}?${urlParams.toString()}`;
        }

        const { json } = await httpClient(url);

        if (
          !json ||
          !json.data ||
          !Array.isArray(json.data.data) ||
          typeof json.data.total_count !== "number"
        ) {
          console.error("API call returned invalid data");
          return;
        }

        const total = json.data.total_count;
        const data = json.data.data.map((item) => ({
          ...item,
          id: item._id,
        }));

        return { data, total };
      }
    } catch (error) {
      console.error("API call failed:", error);
    }
  },

  getListWithoutPagination: async (resource, params) => {
    try {
      const type = params.filter ? params.filter.type : null;
      const searchTerm = params.filter ? params.filter.searchTerm : null;

      let url = `${apiUrl}${apiEndpoints[resource]}`;
      const urlParams = new URLSearchParams();

      if (urlParams.toString()) {
        url = `${url}?${urlParams.toString()}`;
      }

      const { json } = await httpClient(url);

      if (!json || !json.data || !Array.isArray(json.data)) {
        console.error("API call returned invalid data");
        return;
      }

      const total = json.data.length;
      const data = json.data.map((item) => ({
        ...item,
        id: item._id,
      }));

      return { data, total };
    } catch (error) {
      console.error("API call failed:", error);
      // Handle the error accordingly
    }
  },



  getOne: async (resource, params) => {
  if (resource === 'listAgents') {
    try {
      // Ensimmäinen API-kutsu
      const url = `${apiUrl}Admin/getAgentDetails?agentId=${params.id}`;
      const { json: firstJson } = await httpClient(url);
      const profileData = firstJson.data[0];
      const adaptedData = { ...profileData, id: profileData._id };

      // Toisen API-kutsun käsittely
      const secondUrl = `${apiUrl}Admin/getAgentListing?agentId=${params.id}`;
      const { json: secondJson } = await httpClient(secondUrl);
      const data2Data = secondJson.data.map(item => ({
        ...item,
        id: item._id
      }));

      // Kolmannen API-kutsun käsittely
       const thirdUrl = `${apiUrl}Admin/getAnAgentsProposals`;
       let prefixedThirdJson = {}; // Alusta tyhjänä objektina

       try {
         const { json: thirdJson } = await httpClient(thirdUrl, {
           method: 'POST',
           headers: new Headers({
             'Content-Type': 'application/x-www-form-urlencoded'
           }),
           body: `_id=${params.id}`
         });
         console.log('Raw data from API:', thirdJson.data);
         prefixedThirdJson = addPrefixToKeys(thirdJson.data || [], 'data3_');
         console.log('Processed data with prefixes:', prefixedThirdJson);
       } catch (error) {
         console.error('API call failed:', error);
         // Tässä kohtaa voit asettaa virheviestin tai käsitellä virhetilannetta
       }
       
       // Jatka sovelluksen suorittamista, vaikka API-kutsu epäonnistuisi
      // Neljännen API-kutsun käsittely
      const fourthUrl = `${apiUrl}User/AdminViewTransactionHistory`;
      const { json: fourthJson } = await httpClient(fourthUrl, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
        body: `id=${params.id}`
      });
      const prefixedFourthJson = addPrefixToKeys(fourthJson.data || [], 'data4_');

      // Viidennen API-kutsun käsittely
      const fifthUrl = `${apiUrl}Admin/getAgentPaymentMethods?agentId=${params.id}`;
      const { json: fifthJson } = await httpClient(fifthUrl);
      const data5Data = fifthJson.data.map(item => ({
        ...item,
        id: item._id
      }));

      // Kuudennen API-kutsun käsittely
      const sixthUrl = `${apiUrl}Admin/listProfileData?agentId=${params.id}`;
      const { json: sixthJson } = await httpClient(sixthUrl);
      const profileListData = sixthJson.data.map(item => ({
        ...item,
        id: item._id
      }));

      // Yhdistetään kaikki datat
      const combinedData = {
        ...adaptedData,
        data2Data,
        data3Data: prefixedThirdJson,
        data4Data: prefixedFourthJson,
        data5Data,
        data6Data: profileListData
      };

      return { data: combinedData };
    } catch (error) {
      console.error('getOne-funktion virhe:', error);
      throw new Error('Datan haku epäonnistui');
    }
  } else if (resource === 'listAgency') {
      try {
        // Ensimmäinen API-kutsu
        const url = `${apiUrl}Admin/getAgentDetails?agentId=${params.id}`;
        const { json: firstJson } = await httpClient(url);
        const profileData = firstJson.data[0];
        const adaptedData = { ...profileData, id: profileData._id };
  
        // Toisen API-kutsun käsittely
        const secondUrl = `${apiUrl}Admin/getAgentListing?agentId=${params.id}`;
        const { json: secondJson } = await httpClient(secondUrl);
        const data2Data = secondJson.data.map(item => ({
          ...item,
          id: item._id
        }));
  

         
         // Jatka sovelluksen suorittamista, vaikka API-kutsu epäonnistuisi
        // Neljännen API-kutsun käsittely
        const fourthUrl = `${apiUrl}User/AdminViewTransactionHistory`;
        const { json: fourthJson } = await httpClient(fourthUrl, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded'
          }),
          body: `id=${params.id}`
        });
        const prefixedFourthJson = addPrefixToKeys(fourthJson.data || [], 'data4_');
  
        // Viidennen API-kutsun käsittely
        const fifthUrl = `${apiUrl}Admin/getAgentPaymentMethods?agentId=${params.id}`;
        const { json: fifthJson } = await httpClient(fifthUrl);
        const data5Data = fifthJson.data.map(item => ({
          ...item,
          id: item._id
        }));
  
        // Kuudennen API-kutsun käsittely
        const sixthUrl = `${apiUrl}Admin/listProfileData?agentId=${params.id}`;
        const { json: sixthJson } = await httpClient(sixthUrl);
        const profileListData = sixthJson.data.map(item => ({
          ...item,
          id: item._id
        }));
  
        // Yhdistetään kaikki datat
        const combinedData = {
          ...adaptedData,
          data2Data,
         // data3Data: prefixedThirdJson,
          data4Data: prefixedFourthJson,
          data5Data,
          data6Data: profileListData
        };
  
        return { data: combinedData };
      } catch (error) {
        console.error('getOne-funktion virhe:', error);
        throw new Error('Datan haku epäonnistui');
      }
    } else {

      try {
        function validateAndCleanResource(resource) {
          if (typeof resource !== "string") {
            throw new Error("Resource pitää olla merkkijono");
          }
          // Lisää tarvittaessa muita tarkistuksia ja puhdistuslogiikkaa
          return resource.trim(); // Poista välilyönnit alusta ja lopusta
        }

        // Tarkista ja puhdista resource
        const cleanedResource = validateAndCleanResource(resource);

        // Tarkista onko resource validi
        if (!apiEndpoints[cleanedResource]) {
          throw new Error(
            `Resource "${cleanedResource}" ei vastaa mitään API-endpointtia`
          );
        }

        // Rakenna URL
        let url = `${apiUrl}${apiEndpoints[cleanedResource]}`;
        const urlParams = new URLSearchParams({});

        // Lähetä GET-pyyntö
        const { json } = await httpClient(`${url}?${urlParams.toString()}`);

        if (!json || !json.data) {
          throw new Error("Dataa ei löytynyt");
        }

        // Etsi haluttu tietue
        const record = json.data.data.find(
          (r) => r._id != undefined && r._id.toString() === params.id
        );
        if (!record) {
          throw new Error("Tietuetta ei löydy");
        }

        return { data: { ...record, id: record._id } };
      } catch (error) {
        console.error("getOne-virhe:", error);
        throw new Error("Tietueen hakeminen epäonnistui");
      }
    }
  },

  create: (resource, params) => {
    const url = `${apiUrl}${apiEndpoints[resource]}`;
    return httpClient(url, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  update: async (resource, params) => {
    if (resource === "listAgents") {

      const imagesToUpload = ['companyImage', 'profilePicture'];
      for (const imageName of imagesToUpload) {
        if (params.data[imageName] && params.data[imageName].rawFile) {
          console.log(`${imageName} kuvatiedosto löydetty, valmistellaan lähettämistä...`);
          const formData = new FormData();
          formData.append('pic', params.data[imageName].rawFile);
  
          try {
            console.log(`Lähetetään ${imageName} kuva API:lle...`);
            const imageResponse = await axios.post('https://api.salaovi.fi/User/uploadAttachment', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            console.log(`${imageName} kuva lähetetty, vastaus API:lta:`, imageResponse);
  
            // Päivitä kuvan URL osoite
            params.data[imageName] = imageResponse.data.data.s3Url;
          } catch (error) {
            console.error(`Virhe ${imageName} kuvan lähettämisessä:`, error);
          }
        } else {
          console.log(`Ei ${imageName} kuvatiedostoa lähetettäväksi.`);
        }
      }
  
      const agentUpdateUrl = `${apiUrl}Admin/editAgentAccountSettings`;
      const companyUpdateUrl = `${apiUrl}Admin/editAgentCompanyDetails`;

      const agentData = {
        agentId: params.id,
        fullName: params.data.fullName,
        email: params.data.email,
        profilePicture:  params.data.profilePicture,
        countryCode: params.data.countryCode,
        contactNumber: params.data.contactNumber,
        address: params.data.address,
        city: params.data.city,
        zipcode: "null",
        lat: "null",
        lng: "null",
        superAgent: params.data.superAgent,
        vacationMode: params.data.vacationMode,
        plan:  params.data.plan
      };

      const companyData = {
        agentId: params.id,
        companyName: params.data.companyName,
        companyImage: params.data.companyImage,
        jobTitle: params.data.jobTitle,
        address: params.data.address,
        startWorking: params.data.startWorking.toString(),
        agency: params.data.agency.toString(),
        compete_with_agency:
          params &&
          params.data &&
          params.data.compete_with_agency !== null &&
          params.data.compete_with_agency !== undefined
            ? params.data.compete_with_agency
            : "ei sivua",
        profile_link: params.data.profile_link,
      };

      return Promise.all([
        httpClient(agentUpdateUrl, {
          method: "PUT",
          headers: new Headers({ "Content-Type": "application/json" }),
          body: JSON.stringify(agentData),
        }),
        httpClient(companyUpdateUrl, {
          method: "PUT",
          headers: new Headers({ "Content-Type": "application/json" }),
          body: JSON.stringify(companyData),
        }),
      ])
        .then(([agentResponse, companyResponse]) => {
          const agentJson = agentResponse.json;
          const companyJson = companyResponse.json;

          if (
            !agentJson ||
            agentJson.statusCode !== 200 ||
            !companyJson ||
            companyJson.statusCode !== 200
          ) {
            console.error("Unexpected response format or empty response");
            return { data: {} };
          }

          const adaptedData = {
            ...params.data,
            id: params.id,
          };
          return { data: adaptedData };
        })
        .catch((error) => {
          console.error("Virhe pyynnössä:", error);
        });
    } else {
      // Yleinen käsittely kaikille muille resursseille
      const updateUrl = apiEndpoints[resource]
        ? `${apiUrl}${apiEndpoints[resource]}`
        : `${apiUrl}${resource}`;

      return httpClient(updateUrl, {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(params.data),
      })
        .then((response) => {
          const jsonResponse = response.json;

          if (!jsonResponse || jsonResponse.statusCode !== 200) {
            console.error("Unexpected response format or empty response");
            return { data: {} };
          }

          const adaptedData = {
            ...params.data,
            id: params.id,
          };
          return { data: adaptedData };
        })
        .catch((error) => {
          console.error("Virhe pyynnössä:", error);
        });
    }
  },

  generalUpdate: (resource, params) => {
    // Etsi oikea API-endpoint tai käytä oletusta
    const updateUrl = `${apiUrl}${apiEndpoints[resource] || resource}`;

    return httpClient(updateUrl, {
      method: "PUT",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(params.data),
    })
      .then((response) => response.json())
      .then((jsonResponse) => {
        if (jsonResponse.statusCode !== 200) {
          console.error("Unexpected response format or empty response");
          return { data: {} };
        }

        const Data = {
          ...params.data,
          id: params.id,
        };
        return { data: Data };
      })
      .catch((error) => {
        console.error("Virhe pyynnössä:", error);
      });
  },

  // Handle authentication

  logout: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },

  // Check if the user is authenticated
  checkAuth: () => {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },

  // Handle errors
  checkError: (error) => {
    // Handle error logic here
    return Promise.reject(error);
  },

  // Get user permissions
  getPermissions: () => {
    const role = localStorage.getItem("role");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};




