import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './components/key-account.module.css';
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import { KeyAccountMail } from './components/KeyAccountMail';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const CreateEnquiryPage = () => {
    const [customerData, setCustomerData] = useState({
        fullName: '',
        email: '',
        confirmEmail: '',
        countryCode:'+358',
        contactNumber: '',
        address: '',
        zipCode: '',
        lat: '',
        lng: '',
        city: '',
        size:'',
        type: 'CUSTOMER',
        deviceToken:'fdsafdsagfd324',
        Images: '[https://www.salaovi.fi/wp-content/uploads/2021/04/LOGODUM.jpg]', 
    });
    const [emailMessage, setEmailMessage] = useState('');
    const [generatedLink, setGeneratedLink] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [password, setPassword] = useState('');

    function generatePassword() {
      const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let password = "";
      for (let i = 0; i < 8; i++) {
          const randomIndex = Math.floor(Math.random() * chars.length);
          password += chars[randomIndex];
      }
      return password;
  }
  
    // Luo salasana, kun komponentti ladataan
    useEffect(() => {
      const newPassword = generatePassword();
      setPassword(newPassword);

      // Päivitä salasana customerData-objektiin
      setCustomerData(prevData => ({
          ...prevData,
          password: newPassword
      }));
  }, []);


    
    const handleScriptLoad = () => {
        setIsScriptLoaded(true);
      };

   

  const convertToHtml = (text) => {
      return text.split('\n\n')
      .map(paragraph => `<p>${paragraph.replace(/\n/g, '<br>')}</p>`).join('');
  };







  

 
    const handleAgencyChange = (event) => {
        // Päivittää tilan uudella valitulla arvolla
        setCustomerData({
          ...customerData,
          agency: event.target.value
        });
      };

     
    

    const renderDropdownField = (name, label, value, handleChange) => {
        return (
            <select
            select
            label={label}
            value={value}
            onChange={handleChange}
            className={styles.input}
            fullWidth
            SelectProps={{
              native: true,
            }}
          >
            <option value="6080001e12ac3e0df4a35619">Työskenteleekö näissä?</option>
            <option value="6075a6fff368204a35dc7ef0">RE/MAX</option>
            <option value="6075a70cf368204a35dc7ef2">Huom!</option>
            <option value="6075a728f368204a35dc7ef6">Kiinteistömaailma</option>
            <option value="6075a717f368204a35dc7ef3">Roof Group</option>
            <option value="6075a731f368204a35dc7ef7">Huoneistokeskus</option>
            <option value="6075a721f368204a35dc7ef5">Sp-Koti</option>
            <option value="6075a71cf368204a35dc7ef4">Aninkainen</option>
            <option value="6075a739f368204a35dc7ef8">Glik</option>
            <option value="6075a706f368204a35dc7ef1">Kahdeksas päivä</option>
            <option value="6075a740f368204a35dc7ef9">Solid House</option>
            <option value="6076a4457c6ce14bfa9be56b">Kotijoukkue</option>
            <option value="6076a4fc7c6ce14bfa9be56c">Bo LKV</option>
            <option value="652d332b1467be0fbc71f0b0">Neliöt Liikkuu</option>
            </select>
        );
      };



const [autocomplete, setAutocomplete] = useState(null);

const onLoad = (autocomp) => setAutocomplete(autocomp);

const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      
      if (!place.geometry) {
        // Käyttäjä syötti paikan nimen, mutta valitsi ei mitään pudotusvalikosta
        return;
      }
 // Etsi tarvittavat komponentit place.address_components-objektista
 const streetNumber = place.address_components.find(component => component.types.includes('street_number'))?.long_name || '';
 const route = place.address_components.find(component => component.types.includes('route'))?.long_name || '';
 const locality = place.address_components.find(component => component.types.includes('locality'))?.long_name || '';

 // Yhdistä komponentit muodostaaksesi täydellisen osoitteen
 const address = `${route} ${streetNumber}, ${locality}`.trim();

      // Päivitä kentät vastaamaan valittua paikkaa
      setCustomerData(prevcustomerData => ({
        ...prevcustomerData,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
       address: address, // Käytä yhdistettyä osoitetta
        city: place.address_components.find(component => component.types.includes('locality'))?.long_name || '',
        zipCode: place.address_components.find(component => component.types.includes('postal_code'))?.short_name || '',
      }));
    } else {

    }
  };

    
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Lisätään validointi puhelinnumeron kenttään
    if (name === 'contactNumber') {
        // Sallitaan vain numerot ja poistetaan kaikki muut merkit
        const sanitizedValue = value.replace(/[^0-9]/g, '');
        setCustomerData({ 
            ...customerData, 
            [name]: sanitizedValue 
        });
    } else if (name === 'emailMessage') {
        setEmailMessage(value);
    } else {
        setCustomerData({ 
            ...customerData, 
            [name]: type === 'checkbox' ? checked : value 
        });
    }
};

const handleSubmitRegister = async (event) => {
    event.preventDefault(); 
    // Validointitarkistukset
    if (!customerData.fullName) {
        toast.error('Ole hyvä ja lisää nimesi.');
        return;
    }
    if (!customerData.email) {
        toast.error('Sähköpostiosoite on pakollinen.');
        return;
    }
    if (!customerData.contactNumber) {
        toast.error('Puhelinnumero on pakollinen.');
        return;
    }

    try {
        // Valitse lähetettävät kentät rekisteröintiä varten
        const registerData = {
            fullName: customerData.fullName,
            email: customerData.email,
            contactNumber: customerData.contactNumber,
            address: customerData.address,
            lat: customerData.lat,
            lng: customerData.lng,
            city: customerData.city,
            zipCode: customerData.zipCode,
            password: customerData.password,
            type: customerData.type,
            deviceToken: customerData.deviceToken,
            countryCode: customerData.countryCode
            // lisää muita tarvittavia kenttiä tähän
        };
        
        const registerFormBody = Object.keys(registerData).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(registerData[key])).join('&');

        // Rekisteröi käyttäjä
        let response = await axios.post('https://api.salaovi.fi/User/Register', registerFormBody, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json'
            }
        });

        if (response.status === 200 && response.data.data && response.data.data.accessToken) {
            console.log("Rekisteröinti onnistui", response.data);
        
            // Haetaan token vastauksesta
            const token = response.data.data.accessToken;
        
            // Tulostetaan token konsoliin varmistamaan sen olemassaolo
            console.log("Token:", token);
        
            // Muokkaa dataa createEnquiry-pyyntöä varten
            const enquiryData = {
                name: customerData.fullName,
                email: customerData.email,
                contactNumber: customerData.contactNumber,
                address: customerData.address,
                lat: customerData.lat,
                lng: customerData.lng,
                city: customerData.city,
                zipCode: customerData.zipCode,
                comment:'Ei Lisätietoja',
                size: customerData.size,
                images: ['https://www.salaovi.fi/wp-content/uploads/2023/11/pexels-photo-681368-960x720.jpeg'],
                // lisää muita tarvittavia kenttiä tähän
            };

            const enquiryFormBody = Object.keys(enquiryData).map(key => {
                // Jos kenttä on taulukko, käytä JSON.stringifyä säilyttääksesi sen rakenteen
                const value = Array.isArray(enquiryData[key]) ? JSON.stringify(enquiryData[key]) : enquiryData[key];
                return encodeURIComponent(key) + '=' + encodeURIComponent(value);
            }).join('&');


            // Lähetä `createEnquiry`-pyyntö
            response = await axios.post('https://api.salaovi.fi/User/createEnquiry', enquiryFormBody, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `${token}`
                }
            });

            if (response.status === 200) {
                console.log("Tiedustelu lähetetty onnistuneesti", response.data);
                toast.success('Tiedustelu lähetetty onnistuneesti.');
            } else {
                console.log("Tiedustelun lähetys epäonnistui", response);
                toast.error(`Tiedustelun lähetys epäonnistui: ${response.data.message || 'Tuntematon virhe'}`);
            }
        } else {
            console.log("Rekisteröinti epäonnistui", response);
            toast.success(`Rekisteröinti onnistui: ${response.data.message || 'Tuntematon virhe'}`);
        }
    } catch (error) {
        console.error("Virhe prosessissa:", error);
        if (error.response) {
            // Virhe palvelimelta
            toast.error(`Virhe: ${error.response.data.message || 'Tuntematon virhe'}`);
        } else {
            // Virhe, joka ei ole peräisin palvelimelta
            toast.error('Virhe prosessissa.');
        }
    }
};






    return (

        
    <div className={styles.container}>
       
       <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 99099 }} // Tärkeä osa: varmistetaan, että ToastContainer on päällimmäisenä
      />
<>
          
            <h1>Asiakastesteri</h1>
            <form onSubmit={handleSubmitRegister} className={styles.form}>
   
            <input
                type="text"
                name="fullName"
                placeholder="Nimi"
                value={customerData.fullName}
                onChange={handleInputChange}
                className={styles.input}
            />
    
           

        <input
                type="email"
                name="email"
                placeholder="Sähköposti"
                value={customerData.email}
                onChange={handleInputChange}
                className={styles.input}
            />

       
   

      
       
               
       
                    

 
 <input
            type="text"
            name="contactNumber"
            placeholder="Puhelinnumero"
            value={customerData.contactNumber}
            onChange={handleInputChange}
            className={styles.input}
        />
         <input
            type="text"
            name="size"
            placeholder="Asunnon koko m2"
            value={customerData.size}
            onChange={handleInputChange}
            className={styles.input}
        />
     
      
        <LoadScript
          googleMapsApiKey="AIzaSyB6TNkd9iA632efQoYSh0eM4iehWrtNV_4"
          libraries={['places']}
          language="fi"
          region="FI"
          onLoad={handleScriptLoad}
        >
          {isScriptLoaded && (
            <Autocomplete
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}
          >
            
                <input
                required
      fullWidth
      type="text"
      name="address"
      value={customerData.address || ''}
      label="Osoite" 
      onChange={handleInputChange}// Voit vaihtaa tämän haluamaksesi nimikkeeksi
      className={styles.input_adress}
    />
          </Autocomplete>
          )}
        </LoadScript>

          
          
        <div className={styles.inputField}>
        
               
       
</div>
       
                
              <button className={styles.button}>Lähetä</button>
            </form>
            </>
          
        </div>
        
  
  
    );
};



    

export default CreateEnquiryPage;
