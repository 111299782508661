import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './components/key-account.module.css';
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import { KeyAccountMail } from './components/KeyAccountMail';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const CreateLinkPage = () => {
    const [customerData, setCustomerData] = useState({
        fullName: '',
        email: '',
        confirmEmail: '',
        contactNumber: '',
        vat: '',
        companyName: '',
        jobTitle: '',
        address: '',
        zipCode: '',
        startWorking: '',
        lat: '',
        lng: '',
        city: '',
        compete_with_agency: false, // Boolean-arvo
        legalRealtor: false, // Boolean-arvo
        type: 'AGENT', 
        offer:'',
        emailMessage:'',
        maxDistance: 55000,
        countryCode: '+358',
        password:'',
        deviceToken: 'null',
        companyImage: 'https://www.salaovi.fi/wp-content/uploads/2021/04/LOGODUM.jpg',
        profilePicture: 'https://www.salaovi.fi/wp-content/uploads/2021/04/dsadsdff.jpg',
        about: 'Kerro lyhyesti itsestäsi',
        app_version: 3, // Numeerinen arvo
        agency: '6080001e12ac3e0df4a35619',
        compete_with_agency: true, // Boolean-arvo
        profile_link: 'null',
        deviceType: 'IOS',
        type: 'AGENT',
        deviceToken: 'null',
      
    });
    const [emailMessage, setEmailMessage] = useState('');
    const [generatedLink, setGeneratedLink] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [password, setPassword] = useState('');

    function generatePassword() {
      const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let password = "";
      for (let i = 0; i < 8; i++) {
          const randomIndex = Math.floor(Math.random() * chars.length);
          password += chars[randomIndex];
      }
      return password;
  }
  
    // Luo salasana, kun komponentti ladataan
    useEffect(() => {
      const newPassword = generatePassword();
      setPassword(newPassword);

      // Päivitä salasana customerData-objektiin
      setCustomerData(prevData => ({
          ...prevData,
          password: newPassword
      }));
  }, []);


    
    const handleScriptLoad = () => {
        setIsScriptLoaded(true);
      };

    useEffect(() => {
        const firstName = customerData.fullName.split(' ')[0]; // Ottaa ensimmäisen sanan fullName-kentästä
      
        let offerText = '';
        if (customerData.offer) {
          offerText = `Kuten sovimme rekisteröitymiseenne sisältyy ${customerData.offer} kpl ilmaisia asiakkaita.\n\n`;
        }
      
        if (firstName && generatedLink) {
          setEmailMessage(
            `Hei ${firstName},\n\n` +
            `Mahtavaa saada teidät yhteistyökumppaniksi Salaoveen. ` +
            `Ohessa kirjautumistiedot sekä linkki, jonka kautta voitte viimeistellä rekisteröinnin:\n\n` +
            `${generatedLink}\n\n` +
            `Linkki on tietoturvasyistä voimassa 24h\n\n` +
            offerText+
            `Kirjautumistiedot\n\n` +
            `Sähköposti: ${customerData.email}\n` +
            `Salasana: ${password}\n\n` 
            
          
            
          );
        }
      }, [customerData, generatedLink]);

  const convertToHtml = (text) => {
      return text.split('\n\n')
      .map(paragraph => `<p>${paragraph.replace(/\n/g, '<br>')}</p>`).join('');
  };


  const handleSubmitEmail = async () => {
    try {
        const htmlFormattedMessage = convertToHtml(emailMessage);
        const fullHtmlContent = KeyAccountMail(htmlFormattedMessage);

        const response = await axios.post('https://partners.salaovi.fi/api/send-email', {
            email: customerData.email,
            subject: "Salaovi rekisteröinti ja kirjautumistiedot",
            htmlContent: fullHtmlContent,
            senderName: "Kalle Salaovesta",
            senderEmail: "kalle.masalin@salaovi.fi"
        });

        if (response.status === 200) {
            // Nollaa lomake ja näytä ilmoitus
            setCustomerData({
                ...customerData,
                fullName: '',
                email: '',
                confirmEmail: '',
                contactNumber: '',
                vat: '',
                companyName: '',
                jobTitle: '',
                address: '',
                zipCode: '',
                startWorking: '',
                lat: '',
                lng: '',
                city: '',
                compete_with_agency: false,
                legalRealtor: false,
                type: '',
                offer: '',
                emailMessage: '',
                maxDistance: 55000,
                countryCode: '+358',
                emailMessage:''
            });
            toast.success("Viesti lähetetty onnistuneesti!");
            setEmailSent(true); // Aseta viestin lähetyksen tila todeksi
            setTimeout(() => setEmailSent(false), 5000); // Piilota ilmoitus 5 sekunnin kuluttua

        } else if (response.status === 204) {
            // Näytä ilmoitus, että palvelimelta ei tullut sisältöä
            toast.info("Täytä ensin lomake ja paine luo viestipohja.");
        }
    } catch (error) {
        console.error("Error sending email:", error);
        setEmailSent(false); // Jos lähetys epäonnistuu, aseta tila epätodeksi
    }
};






  

 
    const handleAgencyChange = (event) => {
        // Päivittää tilan uudella valitulla arvolla
        setCustomerData({
          ...customerData,
          agency: event.target.value
        });
      };

     
    

    const renderDropdownField = (name, label, value, handleChange) => {
        return (
            <select
            select
            label={label}
            value={value}
            onChange={handleChange}
            className={styles.input}
            fullWidth
            SelectProps={{
              native: true,
            }}
          >
            <option value="6080001e12ac3e0df4a35619">Työskenteleekö näissä?</option>
            <option value="6075a6fff368204a35dc7ef0">RE/MAX</option>
            <option value="6075a70cf368204a35dc7ef2">Huom!</option>
            <option value="6075a728f368204a35dc7ef6">Kiinteistömaailma</option>
            <option value="6075a717f368204a35dc7ef3">Roof Group</option>
            <option value="6075a731f368204a35dc7ef7">Huoneistoketju</option>
            <option value="6075a721f368204a35dc7ef5">Sp-Koti</option>
            <option value="6075a71cf368204a35dc7ef4">Aninkainen</option>
            <option value="6075a739f368204a35dc7ef8">Glik</option>
            <option value="6075a706f368204a35dc7ef1">Kahdeksas päivä</option>
            <option value="6075a740f368204a35dc7ef9">Solid House</option>
            <option value="6076a4457c6ce14bfa9be56b">Kotijoukkue</option>
            <option value="6076a4fc7c6ce14bfa9be56c">Bo LKV</option>
            <option value="652d332b1467be0fbc71f0b0">Neliöt Liikkuu</option>
            </select>
        );
      };



const [autocomplete, setAutocomplete] = useState(null);

const onLoad = (autocomp) => setAutocomplete(autocomp);

const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      
      if (!place.geometry) {
        // Käyttäjä syötti paikan nimen, mutta valitsi ei mitään pudotusvalikosta
        return;
      }
 // Etsi tarvittavat komponentit place.address_components-objektista
 const streetNumber = place.address_components.find(component => component.types.includes('street_number'))?.long_name || '';
 const route = place.address_components.find(component => component.types.includes('route'))?.long_name || '';
 const locality = place.address_components.find(component => component.types.includes('locality'))?.long_name || '';

 // Yhdistä komponentit muodostaaksesi täydellisen osoitteen
 const address = `${route} ${streetNumber}, ${locality}`.trim();

      // Päivitä kentät vastaamaan valittua paikkaa
      setCustomerData(prevcustomerData => ({
        ...prevcustomerData,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
       address: address, // Käytä yhdistettyä osoitetta
        city: place.address_components.find(component => component.types.includes('locality'))?.long_name || '',
        zipCode: place.address_components.find(component => component.types.includes('postal_code'))?.short_name || '',
      }));
    } else {

    }
  };

    
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Lisätään validointi puhelinnumeron kenttään
    if (name === 'contactNumber') {
        // Sallitaan vain numerot ja poistetaan kaikki muut merkit
        const sanitizedValue = value.replace(/[^0-9]/g, '');
        setCustomerData({ 
            ...customerData, 
            [name]: sanitizedValue 
        });
    } else if (name === 'emailMessage') {
        setEmailMessage(value);
    } else {
        setCustomerData({ 
            ...customerData, 
            [name]: type === 'checkbox' ? checked : value 
        });
    }
};

const handleSubmitRegister = async () => {
  // Validointitarkistukset
  if (!customerData.fullName) {
      toast.error('Ole hyvä ja lisää nimesi.');
      return;
  }
  if (!customerData.email) {
      toast.error('Sähköpostiosoite on pakollinen.');
      return;
  }
  if (!customerData.contactNumber) {
      toast.error('Puhelinnumero on pakollinen.');
      return;
  }
  if (!customerData.vat) {
      toast.error('Y-tunnus on pakollinen.');
      return;
  }
  if (!customerData.password) {
      toast.error('Salasana on pakollinen.');
      return;
  }
  if (!customerData.companyName) {
      toast.error('Yrityksen nimi on pakollinen.');
      return;
  }
  if (!customerData.jobTitle) {
      toast.error('Titteli on pakollinen.');
      return;
  }
  if (!customerData.address) {
      toast.error('Osoite on pakollinen.');
      return;
  }
  if (!customerData.startWorking) {
      toast.error('Aloitusajankohta on pakollinen.');
      return;
  }

  try {
      const dataToSend = { ...customerData };
      delete dataToSend.confirmEmail;
      delete dataToSend.emailMessage;
      delete dataToSend.offer;
      
      const formBody = Object.keys(dataToSend).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(dataToSend[key])).join('&');

      const response = await axios.post('https://api.salaovi.fi/User/Register', formBody, {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Accept': 'application/json'
          }
      });

      if (response.status === 200) {
          console.log("Rekisteröinti onnistui", response.data);
          await handleSubmitEmail();
          toast.success('Rekisteröinti onnistui.');

                    // Nollaa lomakkeen tila
                    setCustomerData({
                        fullName: '',
                        email: '',
                        confirmEmail: '',
                        contactNumber: '',
                        vat: '',
                        companyName: '',
                        jobTitle: '',
                        address: '',
                        zipCode: '',
                        startWorking: '',
                        lat: '',
                        lng: '',
                        city: '',
                        compete_with_agency: false,
                        legalRealtor: false,
                        type: 'AGENT',
                        offer:'',
                        emailMessage:'',
                        maxDistance: 55000,
                        countryCode: '+358',
                        password:'',
                        deviceToken: 'null',
                        companyImage: 'https://www.salaovi.fi/wp-content/uploads/2021/04/LOGODUM.jpg',
                        profilePicture: 'https://www.salaovi.fi/wp-content/uploads/2021/04/dsadsdff.jpg',
                        about: 'Kerro lyhyesti itsestäsi',
                        app_version: 3,
                        agency: '6080001e12ac3e0df4a35619',
                        compete_with_agency: true,
                        profile_link: 'null',
                        deviceType: 'IOS',
                        type: 'AGENT',
                        deviceToken: 'null',
                    });
                    setEmailMessage(''); // Muista nollata myös sähköpostiviesti
        } else {
          console.log("Rekisteröinti epäonnistui", response);
          toast.error(`Rekisteröinti epäonnistui: ${response.data.message || 'Tuntematon virhe'}`);
      }
  } catch (error) {
      console.error("Rekisteröinnissä tapahtui virhe:", error);
      if (error.response) {
          // Virhe palvelimelta
          toast.error(`Rekisteröinnissä tapahtui virhe: ${error.response.data.message || 'Tuntematon virhe'}`);
      } else {
          // Virhe, joka ei ole peräisin palvelimelta
          toast.error('Rekisteröinnissä tapahtui virhe.');
      }
  }
};
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validointitarkistukset
  if (!customerData.fullName) {
    toast.error('Ole hyvä ja lisää nimesi.');
    return;
}
if (!customerData.email) {
    toast.error('Sähköpostiosoite on pakollinen.');
    return;
}
if (!customerData.contactNumber) {
    toast.error('Puhelinnumero on pakollinen.');
    return;
}
if (!customerData.vat) {
    toast.error('Y-tunnus on pakollinen.');
    return;
}
if (!customerData.password) {
    toast.error('Salasana on pakollinen.');
    return;
}
if (!customerData.companyName) {
    toast.error('Yrityksen nimi on pakollinen.');
    return;
}
if (!customerData.jobTitle) {
    toast.error('Titteli on pakollinen.');
    return;
}
if (!customerData.address) {
    toast.error('Osoite on pakollinen.');
    return;
}
if (!customerData.startWorking) {
    toast.error('Aloitusajankohta on pakollinen.');
    return;
}
        try {
            const response = await axios.post('https://partners.salaovi.fi/api/generate-link', customerData);
            if (response.data) {
                setGeneratedLink(response.data.link);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };






    return (

        
    <div className={styles.container}>
       
       <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 99099 }} // Tärkeä osa: varmistetaan, että ToastContainer on päällimmäisenä
      />
<>
          
            <h1>Key Account manager</h1>
            <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.inputField}>
            <input
                type="text"
                name="fullName"
                placeholder="Nimi"
                value={customerData.fullName}
                onChange={handleInputChange}
                className={styles.input}
            />

<input
    name="jobTitle"
    value={customerData.jobTitle}
    onChange={handleInputChange}
    className={styles.input}
    placeholder="Titteli"
/>

          
           
        </div>
        <div className={styles.inputField}>
        <input
                type="email"
                name="email"
                placeholder="Sähköposti"
                value={customerData.email}
                onChange={handleInputChange}
                className={styles.input}
            />
        <input
            type="email"
            name="confirmEmail"
            placeholder="Vahvista Sähköposti"
            value={customerData.confirmEmail}
            onChange={handleInputChange}
            className={styles.input}
        />
       
        </div>
        
        <div className={styles.inputField}>

        <input
            type="text"
            name="vat"
            placeholder="Y-tunnus"
            value={customerData.vat}
            onChange={handleInputChange}
            className={styles.input}
        />
        <input
            type="text"
            name="companyName"
            placeholder="Yrityksen nimi"
            value={customerData.companyName}
            onChange={handleInputChange}
            className={styles.input}
        />
        </div>
      
       
               
        <div className={styles.inputField}>
        <select
    name="type"
    value={customerData.type}
    onChange={handleInputChange}
    className={styles.input}
>
    <option value="" disabled hidden>Tyyppi</option>
    <option value="AGENT">Kiinteistönvälittäjä rekisteröinti</option>
    <option value="AGENCY">Välitysliike rekisteröinti</option>
</select>

                    <input
                        type="text"
                        name="startWorking"
                        placeholder="Aloitusvuosi"
                        value={customerData.startWorking}
                        onChange={handleInputChange}
                        className={styles.input}
                    />
                    </div>
                    <div className={styles.inputField}>
 {renderDropdownField('agency', 'Työskenteletkö jossain näistä?', customerData.agency, handleAgencyChange)}

 

 <input
            type="text"
            name="contactNumber"
            placeholder="Puhelinnumero"
            value={customerData.contactNumber}
            onChange={handleInputChange}
            className={styles.input}
        />
        {
    customerData.agency !== "6080001e12ac3e0df4a35619" && (
      <label className={styles.label}>
      Kilpaileeko välitysliikkeen sisällä?
        <select
            name="compete_with_agency"
            value={customerData.compete_with_agency}
            onChange={handleInputChange}
            className={styles.input}
        >
            <option value="true">Kyllä</option>
            <option value="false">Ei</option>
        </select>
        </label>
    )
}
        </div>
        <LoadScript
          googleMapsApiKey="AIzaSyB6TNkd9iA632efQoYSh0eM4iehWrtNV_4"
          libraries={['places']}
          language="fi"
          region="FI"
          onLoad={handleScriptLoad}
        >
          {isScriptLoaded && (
            <Autocomplete
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}
          >
            
                <input
                required
      fullWidth
      type="text"
      name="address"
      value={customerData.address || ''}
      label="Osoite" 
      onChange={handleInputChange}// Voit vaihtaa tämän haluamaksesi nimikkeeksi
      className={styles.input_adress}
    />
          </Autocomplete>
          )}
        </LoadScript>

          
          
        <div className={styles.inputField}>
         <input
            type="text"
            name="offer"
            placeholder="ilmaiset asiakkaat kpl"
            value={customerData.offer}
            onChange={handleInputChange}
            className={styles.input}
        />
                

                <div className={styles.inputField}>
                <label className={styles.label}>
                    Onko  LKV?:
                    <input
                        type="checkbox"
                        name="legalRealtor"
                        checked={customerData.legalRealtor}
                        onChange={handleInputChange}
                        className={styles.input}
                    />
                </label>
              
                </div>
       
</div>
       
                
                <button type="submit" className={styles.button}>Luo viestipohja</button>


           
        
            <br></br>
            Sähköpostiviesti:
            <textarea
                className={styles.emailMessage}
                value={emailMessage}
                name="emailMessage"
                onChange={handleInputChange}
                style={{ width: '97%', height: '170px' }}
            />


  
    <button onClick={handleSubmitRegister} className={styles.button}>Lähetä viesti</button>
            </form>
            </>
          
        </div>
        
  
  
    );
};



    

export default CreateLinkPage;
