import { SimpleForm, TextInput, useNotify } from 'react-admin';
import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import { Button } from '@mui/material';
import { EditButton } from 'react-admin';

const AddFundsButton = ({ userId }) => {
    const [open, setOpen] = useState(false);
    const notify = useNotify();


    const handleSend = () => {

        handleRefund(userId, amount);
        setOpen(false);
    };
    const [amount, setAmount] = useState('');
    
    const handleRefund = async (userId, amount) => {

        const token = localStorage.getItem('token');
        const response = await fetch('https://api.salaovi.fi/Admin/addFundsToWallet', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: `amount=${amount}&agentId=${userId}`,
        });
    
        if (response.ok) {

            window.location.reload();
        } else {
            console.error('Adding funds failed');
        }
    };

    const styles = {
        button: {
            backgroundColor: '#3f51b5',  // tai mikä tahansa väri
            color: 'white',
            padding: '10px 8px'
            // ... muut tyylit
        },
    };
    
    return (
        <div>
            <Button  variant="contained" color="primary"  onClick={() => setOpen(true)} >
  LISÄÄ VAROJA LOMPAKKOON
</Button>
            <Dialog maxWidth="xs" fullWidth open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Lisää varoja välittäjän lompakkoon</DialogTitle>
                
                <DialogContent>
                <p>Yhden vertailun hinta on 31€ sis 24% alv</p>
                    <SimpleForm
                    toolbar={null} 
                        submitOnEnter={true}
                        redirect={false}
                        undoable={false}
                        
                    >
                       <TextInput source="amount" label="Summa" autoFocus onChange={(e) => setAmount(e.target.value)} />
                    </SimpleForm>
                </DialogContent>
                <Toolbar>
    <Button variant="contained" color="primary"  onClick={handleSend}>
        lisää
    </Button>
</Toolbar>
            </Dialog>
        </div>
    );
};
export default AddFundsButton;

