import React from 'react';
import NewUsersTable from './components/NewUsersTable';
import MonthlyRevenueChart from './components/MonthlyRevenueChart';
import MonthlyComparisonsChart from './components/MonthlyComparisonsChart';
import UserCount from './components/UserCount';
import LeadCount from './components/LeadCount';
import AgentCount from './components/AgentCount';
import LatestEnquiries from './components/LatestEnquiries'; 
import './components/Dashboard.css';
import MoneyCount from './components/MoneyCount';

const Dashboard = () => {
    const dashboardStyle = {
      display: 'flex',
      flexWrap: 'wrap',
 
    };
  
    const largeGridContainerStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    };
  
    const gridItemStyle = {
      margin: '5px',
      border: 'none',
      '@media (max-width: 768px)': {
        width: '100%', // full width on mobile
        height: 'auto', // adjust height as necessary
      },
    };
  
    const smallGridContainerStyle = {
      '@media (max-width: 768px)': {
        width: '100%', // full width on mobile
      },
    };
  
    return (
        <>
     
      <div className="dashboard-container" style={dashboardStyle}>
        
        <div className="large-grid-container" style={largeGridContainerStyle}>
          <div className="grid-item" style={{ ...gridItemStyle, width: '400px', height: '600px' }}>
            <NewUsersTable />
          </div>
          <div className="grid-item" style={{ ...gridItemStyle, width: '400px', height: '600px' }}>
            <LatestEnquiries />
          </div>
        </div>
        <div className="small-grid-container" style={{ ...gridItemStyle, flexDirection: 'column' }}>
        <div className="grid-item" style={{ ...gridItemStyle, height: '150px' }}>
            <MoneyCount />
          </div>
          <div className="grid-item" style={{ ...gridItemStyle, height: '150px' }}>
            <AgentCount />
          </div>
          <div className="grid-item" style={{ ...gridItemStyle, height: '150px' }}>
            <LeadCount />
          </div>
          <div className="grid-item" style={{ ...gridItemStyle, height: '150px' }}>
            <UserCount />
          </div>
          
        </div>
      </div>
      </>
    );
  };
Dashboard.label = "Hallintapaneeli";
export default Dashboard;




      {/*    <div className="grid-item" style={{ width: '50%', height: '300px' }}>
            <MonthlyRevenueChart />
        </div>
        <div className="grid-item" style={{ width: '100%', height: '300px' }}>
            <MonthlyComparisonsChart />
        </div> */}