import React, { useEffect, useState } from 'react';
import { SimpleForm, required, useNotify, AutocompleteInput, SaveButton  } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';



export const AssignAgentDialog = ({ showDialog, setDialog, agencyId }) => {  // Lisätty agencyId prop
  const [agents, setAgents] = useState([]);
  const notify = useNotify();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.salaovi.fi/Admin/listAgentsWithNoAgency', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setAgents(data.data.map(agent => ({ id: agent._id, name: agent.fullName })));
      } catch (error) {
        console.error('Error fetching agents:', error);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (data) => {
    try {
      const newAgentsArray = Array.isArray(data.newAgents) ? data.newAgents : [data.newAgents];
  
      const response = await fetch('https://api.salaovi.fi/Admin/addAgentsToAgency', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          agencyId: agencyId,
          newAgents: newAgentsArray,
        }),
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        notify('Välittäjä lisätty onnistuneesti');
        setDialog(false);
      } else {
        notify('Error: Ei voitu lisätä välittäjää');
      }
    } catch (error) {
      notify('Error: could not assign agent');
      console.error('Error assigning agent:', error);
    }
  };

  return (
    <div>
    {showDialog && (
      <Dialog
      open={true}
      onClose={() => setDialog(false)}
      PaperProps={{
        style: {
          minWidth: '400px',
        },
      }}
    >
        <DialogTitle>
          Lisää Välittäjä
        </DialogTitle>
        <DialogContent>
        <SimpleForm save={<SaveButton label="Lisää" />} onSubmit={handleSubmit}>
        <AutocompleteInput 
  fullWidth
  label="Valitse välittäjä" 
  source="newAgents" 
  choices={agents.map(agent => ({ id: agent.id, name: agent.name }))} 
  validate={[required()]} 
/>
</SimpleForm>
        </DialogContent>
      </Dialog>
    )}
  </div>

  
  );
};

