import React from 'react';
import './Profilesidebar.css';

const ProfileSidebar = ({ fullName, address, profileImage, companyName, jobTitle, companyImage }) => (
    <div className="sidebar" style={{ width: '250px', padding: '20px', backgroundColor: '#f4f4f4' }}>
        <img 
  src={profileImage} 
  alt={fullName} 
  style={{ 
    width: '200px', 
    height: '200px', 
    borderRadius: '50%', 
    objectFit: 'cover'
  }} 
/>

        <h2>{fullName}</h2>
        <h3>{jobTitle}</h3>
        <img 
  src={companyImage} 
  alt={companyName} 
  style={{ 
    width: '100px', 
    objectFit: 'cover',
    borderRadius: '50%', 

  }} 
/>
        <h4>{companyName}</h4>
        <p>{address}</p>
       
    </div>
);

export default ProfileSidebar 