import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  table: {
    minWidth: 250,

  },
  imageCell: {
    width: '56px', // Profiilikuvan leveys + padding

  },
  narrowCell: {
    width: '210px', // Aseta haluamasi leveys

  },
  nameCell: {
    width: '220px', // Aseta haluamasi leveys

  },
  noBorderCell: {
    border:'none'
  },
});

const NewUsersTable = () => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    dataProvider.getList('listAgents', {
      pagination: { page: 1, perPage: 7 },
      sort: { field: 'updatedAt', order: 'DESC' },
      filter: { type: 'AGENT', isBlocked: false },
    })
    .then(({ data }) => {
      setUsers(data);
      setLoading(false);
    })
    .catch(error => {
      setError(error);
      setLoading(false);
    });
  }, [dataProvider]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const roundedImageStyle = {
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    objectFit: "cover",
  };

  return (
    <TableContainer >
      <h3>Uudet Välittäjät</h3>
      <Table className={classes.table} size="small"sx={{ border: 0 }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.imageCell}></TableCell>
            <TableCell className={classes.nameCell}></TableCell>
            <TableCell className={classes.narrowCell}></TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell className={classes.imageCell}>
                <img style={roundedImageStyle} src={user.profilePicture} alt="Profiilikuva" />
              </TableCell>
              <TableCell className={classes.nameCell}>{user.fullName}</TableCell>
              <TableCell className={classes.narrowCell}>{user.city}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NewUsersTable;
