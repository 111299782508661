import React from 'react';
import { useNotify, useRefresh, Button } from 'react-admin';
import axios from 'axios';

const ConfirmActions = ({ record }) => {
    const notify = useNotify();
    const refresh = useRefresh();

    const confirmVerified = () => {
        if (window.confirm('Vahvistetaanko tämä vertailu?')) {
            confirmVerifiedServer(record, true);
        }
    };

    const confirmReject = () => {
        if (window.confirm('Hylätäänkö tämä vertailu?')) {
            confirmVerifiedServer(record, false);
        }
    };

    const confirmVerifiedServer = async (data, type) => {
        const token = localStorage.getItem('token');
        const obj = {
            enquiryId: data.id,  // Huom! Muutin _id -> id. Tarkista oikea kenttä.
            status: type
        };
    
        try {
            await axios.post('https://api.salaovi.fi/Admin/approveEnquiry', obj, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${token}` // Lisätty Bearer token tässä.
                }
            }); 
            notify('Update successful', 'success');
            refresh();
        } catch (error) {
            notify('Something went wrong', 'warning');
        }
    };
    
// Ohjausnappi päivitetty avamaan uuteen ikkunaan
const redirectToOffers = () => {
    window.open(`https://partners.salaovi.fi/tarjoukset/${record.id}`, '_blank');
  };

    return (
        <div>
         {record.adminApproved === true && (
                <>
              <Button
  label="tarjoukset"
  onClick={redirectToOffers}
  style={{
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "14px 20px",
    margin: "8px 0",
    border: "none",
    cursor: "pointer",
    borderRadius: "4px",
    width: "100px"
  }}
/>

                </>
            )}
        {record.adminApproved === false && <span style={{color: 'red'}}>Hylätty</span>}
        {record.awaitingApproval === true && (
            <>
            <button style={{
                backgroundColor: "#4CAF50",
                color: "white",
                padding: "14px 20px",
                margin: "8px 0",
                border: "none",
                cursor: "pointer",
                borderRadius: "4px",
                width: "100px"
            }} onClick={confirmVerified}>Hyväksy</button>

            <button style={{
                backgroundColor: "#f44336",
                color: "white",
                padding: "14px 20px",
                margin: "8px 0",
                border: "none",
                cursor: "pointer",
                borderRadius: "4px",
                width: "100px"
            }} onClick={confirmReject}>Hylkää</button>
        </>
        )}
        </div>
    );
};

export default ConfirmActions;
